import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CloudDownload, ErrorOutline, Print } from "@mui/icons-material";
import { Box, Button, Card, FormControlLabel, FormLabel, Grid, Icon, MenuItem, Radio, RadioGroup, TextField, styled } from "@mui/material";
import { Loader } from "app/components";
import { getAllProjectLocationListForReports, getAllWorkPlanDetails } from "app/redux/actions/ProjectLocationActions";
import { getAllWorkPlanNosByParam, getContractWorkPlanReport } from "app/redux/actions/ReportsAction";
import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from 'app/utils/Constants';
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation } from "app/utils/utils";
import { SimpleCard } from "components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";


const styles = {
  tableCell: {
    textAlign: "center"
  },
  subHeader: {
    textAlign: "center",
    fontWeight: 700
  }

}
const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));




export const ContractWorkPlanReport = () => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isDownloading, setIsDownloading] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [constructionNameList,setConstructionNameList]=useState()
  const [projectDetails, setProjectDetails] = useState({})
  const { workPlanList,reportsProjectLocationList } = useSelector((state) => state.projectLocation);
  const [filteredWorkPlanList,setFilteredWorkPlanList]=useState()
  const [reportType, setReportType] = useState('construction')
  const [originalTableData, setOriginalTableData] = useState()
  const [filteredTableData, setFilteredTableData] = useState()
  const[boqList,setBoqList]=useState()
  const[workPlanDates,setWorkPlanDates]=useState()
  const[workPlanNumbers,setWorkPlanNumbers]=useState()
  const [state, setState] = useState({
    project_location_id: '',
    constructionname_id:null,
    boq_name:null,
    workplan_date:null,
    workplan_no:null
  })


  useEffect(() => {
    dispatch(getAllProjectLocationListForReports(showLoader, hideLoader, showToast))
  }, []);
  const showLoader = () => {
    setLoading(true)
  }
  const showToast = (msg) => {
    const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  }

  const hideLoader = () => {
    setLoading(false)
  }
  const getUniqueArrayOfConstruction=(data)=>{
    const unique = [];
    const uniqueIds = new Set();

    data.forEach(item => {
        if (!uniqueIds.has(item.constructionname_id)) {
            uniqueIds.add(item.constructionname_id);
            unique.push({
                constructionName: item.constructionName,
                constructionname_id: item.constructionname_id
            });
        }
    });
   

    return unique;
}

  const  filterByConstructionNameId=( id)=>{
    return originalTableData.filter(item => item?.constructionname_id === id);
  }
  function getUniqueBoqName(data) {
    const itemList = data.data;
    const boqNames = itemList.map(item => item.boq_name);
    const uniqueBoqNames = [...new Set(boqNames)];
    return uniqueBoqNames;
}
  const getUniqueWorkplanDates=( dataList, boqName)=>{
    const result = dataList
        .filter(item => item.constructionname_id === state?.constructionname_id)
        .flatMap(item => item.data)
        .filter(entry => entry.boq_name === boqName)
        .map(entry => entry.workplan_date);

    const uniqueWorkplanDates = [...new Set(result)];
    return uniqueWorkplanDates;
}
const getUniqueWorkplanNos=(workplanDate)=>{

  const result = originalTableData
      .flatMap(item => item?.constructionname_id===state?.constructionname_id&&item.data)
      .filter(entry => 
          
          entry.boq_name === state?.boq_name &&
          entry.workplan_date === workplanDate
      )
      .map(entry => entry.workplan_no);

  const uniqueWorkplanNos = [...new Set(result)];
  return uniqueWorkplanNos;
}
const getWorkPlanNos=(value)=>{
  const obj={
    constructionname_id:state?.constructionname_id,
    boq_name:state?.boq_name,
    workplan_date:value
  }
  setLoading(true)
  getAllWorkPlanNosByParam(obj)?.then(res=>{
    setLoading(false)
    if(res?.status<300&&res?.data?.success){
      if(res?.data?.obj?.length){
        const uniqueSortedWorkplanNos = [...new Set(res?.data?.obj?.map(item => item.workplan_no))].sort((a, b) => a - b);
        setWorkPlanNumbers(uniqueSortedWorkplanNos)
      }
    }
    else{
      const key = enqueueSnackbar(res?.data?.message, { variant: 'info' });
      SnackBarProperty.info(key, closeSnackbar);
    }
  }).catch(err=>{
  setLoading(false)
  showToast("Something went wrong")
  })
}

function getFilteredTableData() {
  return originalTableData.map(item => {
      if (item.constructionname_id === state?.constructionname_id) {
          const filteredData = item.data.filter(entry => 
              entry.boq_name === state?.boq_name &&
              entry.workplan_date === state?.workplan_date &&
              entry.workplan_no === state?.workplan_no
          );
          return {
              ...item,
              data: filteredData
          };
      }
      return item;
  }).filter(item => item.data.length > 0);
}
useEffect(()=>{
  if(workPlanList?.length>0){
    setFilteredWorkPlanList(workPlanList)
  }
},[workPlanList])

useEffect(()=>{
  if(filteredWorkPlanList&&filteredWorkPlanList?.length>0){
    const duplicateTableData=[...originalTableData]
    duplicateTableData?.forEach((elem)=>{
      if(elem?.constructionname_id===state?.constructionname_id){
        elem.data=filteredWorkPlanList
        const newArr=[]
        newArr.push(elem)
        const filteredDates=getUniqueWorkplanDates(newArr,state?.boq_name)
        setWorkPlanDates(filteredDates)
      }
    })
    setOriginalTableData(duplicateTableData)
  }
},[filteredWorkPlanList])

  const handleChange = (event, source) => {
   setFilteredTableData([])
    if (event.target.name === "project_location_id") {
      // if(reportType==="construction"){
     state.constructionname_id=null
     state.boq_name=null
     state.workplan_date=null
     state.workplan_no=null 
      // }
      setConstructionNameList([])
      setBoqList([])
      setWorkPlanDates([])
      setWorkPlanNumbers([])
      setOriginalTableData([])
      setFilteredTableData()
      setProjectDetails({})
      setFilteredWorkPlanList([])
    
      const filteredArray = reportsProjectLocationList.filter(project => {
        return project.project_location_id === event.target.value
      });
      
        state.project_name = filteredArray[0].project_name
        state.project_location_id=filteredArray[0].project_location_id
        state.project_id =filteredArray[0].project_id
    
  
      setState({ ...state, [event.target.name]: event.target.value });
       if(reportType==="construction"){
        handleFormSubmit(event.target.value )
       }
    } 
    else if(event.target.name === "constructionname_id"){
      setBoqList([])
      setWorkPlanDates([])
      setWorkPlanNumbers([])
      setState({ ...state, [event.target.name]: event.target.value,boq_name:null,workplan_date:null,workplan_no:null });
      const filteredArray=filterByConstructionNameId(event.target.value)
      const boqList=getUniqueBoqName(filteredArray[0])
      setBoqList(boqList)
      setFilteredWorkPlanList([])
    }
    else if(event.target.name === "boq_name"){
      setWorkPlanDates([])
      setWorkPlanNumbers([])
      setFilteredWorkPlanList([])
      setState({ ...state, [event.target.name]: event.target.value,workplan_date:null,workplan_no:null });
      
       dispatch(getAllWorkPlanDetails("name_id", state?.constructionname_id, event.target.value, showLoader, hideLoader, showToast))
      // const boqList=getUniqueBoqName(filteredArray[0])
      // setBoqList(boqList)
    }
    else if(event.target.name === "workplan_date"){
      setWorkPlanNumbers([])
      setState({ ...state, [event.target.name]: event.target.value,workplan_no:null });
      //  const filteredNos=getUniqueWorkplanNos(event.target.value)
      //  setWorkPlanNumbers(filteredNos)
       getWorkPlanNos(event?.target?.value)

    }
    else if(event.target.name === "workplan_no"){
      setState({ ...state, [event.target.name]: event.target.value});

    }
    else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  }
 

  const handleFormSubmit = (project_location_id) => {
    if(state?.constructionname_id&&state?.constructionname_id!=null&&state?.boq_name&&state?.workplan_date&&state?.workplan_no){

    const filteredArray=getFilteredTableData()
     if(filteredArray?.length===0){
      const key = enqueueSnackbar("No Details Found", { variant: 'info' });
      SnackBarProperty.info(key, closeSnackbar);
     }
     else{
     setFilteredTableData(filteredArray)
     }
    }
    else{
    setLoading(true)
    // setOriginalTableData([])
    getContractWorkPlanReport({ project_location_id: state?.project_location_id ??project_location_id})
      .then((res) => {
        setLoading(false)

        if (res?.status < 300) {
          
          if (res?.data?.obj?.item) {
            setProjectDetails({
              company: res?.data?.obj?.agency_name,
              project_name: res?.data?.obj?.item?.project_name,
              project_id: res?.data?.obj?.item?.project_id,
              contractNo: res?.data?.obj?.item?.tender_id,
              workPlanNo: res?.data?.obj?.item?.workorder_no,
              header: res?.data?.obj?.header_logo,
              footer: res?.data?.obj?.footer_logo

            })
          }
          if (res?.data?.obj && res?.data?.obj?.item && res?.data?.obj?.item?.construction_details?.length > 0) {
            //  if (reportType === "report") {
            const tableDataList = res?.data?.obj?.item?.construction_details?.map((elem) => {
              const constructionName = elem?.constructionname_name
              const dataList = elem?.item_list?.map((item, index) => {
                return {
                  sl_no: index + 1,
                  stage_name: item?.stage_name,
                  start_date: item?.start_date,
                  end_date: item?.end_date,
                  remarks: item?.remarks ?? "",
                  boq_name:item?.boq_name,
                  workplan_date: item?.workplan_date,
                  workplan_no: item?.workplan_no
                }
              })
              const obj = {
                constructionName: constructionName,
                constructionname_id: elem?.constructionname_id,
                data: dataList
              }
              return obj
            })
            setOriginalTableData(tableDataList)
            if(reportType==="construction"){
            setConstructionNameList(getUniqueArrayOfConstruction(tableDataList))
            }
          }
          else {
            const key = enqueueSnackbar("No Work Plan Found", { variant: 'info' });
  SnackBarProperty.info(key, closeSnackbar);

        }

        }
        else {
            const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
  SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(error => {
        setLoading(false)
         const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
  SnackBarProperty.error(key, closeSnackbar);

      })
    }



  }

  const handleReportDownload = (isPrint) => {
    if (isPrint) {
      setIsPrinting(true)
      setIsDownloading(false)
    }
    else {
      setIsPrinting(false)
      setIsDownloading(true)
    }
    const doc = new jsPDF();
    const contentHeight = 150;
    const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image

    doc.setFontSize(12); // Set font size


    html2canvas(headerImage, {
      useCORS: true, // Add this line if the image is from a different domain
      allowTaint: true, // Add this line if the image is from a different domain
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = canvas.width;
                const imgHeight = canvas.height;

                // A4 dimensions in mm
                const pdfWidth = 210;
                const pdfHeight = 297;

                // Calculate aspect ratio
                const aspectRatio = imgWidth / imgHeight;

                // Calculate scaled dimensions to fit within A4 while maintaining aspect ratio
                let scaledWidth = pdfWidth;
                let scaledHeight = pdfWidth / aspectRatio;

                if (scaledHeight > pdfHeight) {
                    scaledHeight = pdfHeight;
                    scaledWidth = pdfHeight * aspectRatio;
                }
      
      //const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      
      // Insert the header image into the PDF
      const topBorderPadding = 10; // Adjust the padding value as needed
      doc.setDrawColor(255, 255, 255); // Black color for border
      doc.setLineWidth(topBorderPadding); // Border width (padding)

      // Draw the top border (padding)
      doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);

      // Insert the header image into the PDF
      doc.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);
      const textX = 15;
      const textY = scaledHeight + 10; // Adjust the value for vertical separation
      const textWidth = doc.getStringUnitWidth("Project Name:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      // Add the text content below the header image
      doc.setFontSize(12);
      


      doc.setFont('helvetica', 'bold'); // Set font to bold
      doc.text(`Contract Work Plan`, (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth(`Contract Work Plan`) * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY);
      doc.setFontSize(8); // Set font size
      doc.text("Project Name:", textX, textY+10);
     
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.project_name, textX + textWidth -5, textY+10);
      doc.setFont('helvetica', 'bold');
      doc.text("Client:", textX, textY + 15);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.company, textX + textWidth -5, textY + 15);
      doc.setFont('helvetica', 'bold');
      doc.text("Project ID:", textX, textY + 20);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.project_id}`, textX + textWidth -5, textY + 20);

      const headerSectionHeight = scaledHeight+35; // Adjust the value as needed

      // Calculate the starting Y-coordinate for the table
      const tableStartY = headerSectionHeight + 5;
      doc.setFont('helvetica', 'normal'); // Reset font to normal
      function transformData(data) {
        const transformedData = [];
        data.forEach((section) => {
          // Create a row for the section header value (spanning across all 8 columns)
          const headerRow = [{ content: section.constructionName, colSpan: 5, styles: { fontStyle: "bold",fontSize:8 } }];
          transformedData.push(headerRow);
          // Loop through the data of the section and create rows for each object in the data
          section.data.forEach((row,index) => {
            //const rowData = Object.values(row); // Extract values of each object
            // const rate=formatNumberWithCommasAndDecimals(rowData?.amount )
            // rowData.amount=rate
            if(reportType==="construction"){
              transformedData.push([
                {content:index+1,styles:{fontSize: 8}},
                {content:row?.stage_name,styles:{fontSize: 8}},
                {content:moment(row?.start_date).format("DD-MM-YYYY"),styles:{fontSize: 8}},
                {content:moment(row?.end_date).format("DD-MM-YYYY"),styles:{fontSize: 8}},
                {content:row?.remarks??"",styles:{fontSize: 8}}
              ])
            }
            else{
              transformedData.push([
                {content:index+1,styles:{fontSize: 8}},
                {content:row?.stage_name,styles:{fontSize: 8}},
                {content:row?.boq_name,styles:{fontSize: 8}},
                {content:moment(row?.start_date).format("DD-MM-YYYY"),styles:{fontSize: 8}},
                {content:moment(row?.end_date).format("DD-MM-YYYY"),styles:{fontSize: 8}},
                {content:row?.remarks??"",styles:{fontSize: 8}}
              ])
            }
          });
        });
        return transformedData;
      }

      const transformedData = transformData(filteredTableData);


      doc.autoTable({
        startY: tableStartY,
        head: reportType==="construction"?[[
          { content: "SL NO.", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Stage Name", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Start Date", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "End Date", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Remarks", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } }
        ]]:[[
          { content: "SL NO.", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Stage Name", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "BOQ Name", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Start Date", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "End Date", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Remarks", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } }
        ]],
        body: transformedData, // Use the transformed data here
        theme: "grid",
        styles: {
          fillColor: [255, 255, 255], // Remove background color from the table
          lineColor: [0, 0, 0], // Set border color for main table
          lineWidth: 0.1, // Set border width for main table
          textColor: [0, 0, 0], // Set text color to black
          cellPadding: 2, // Add padding to cells
        },
        columnStyles: {
          1: { cellWidth: 80 },
          2: { halign: "center" },
          3: { halign: "center" },
          //   3: { halign: "right" }, // "UNIT" column width
        },
      });

      // Function to add footer on each page
      function addFooter(pageNumber) {
        // const footerText = "Hello CMS User";
        // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
        const footerX = 0; // Starting X position for the footer
        const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = 10;
        // const availableWidth = pdfWidth - 2 * footerX;

        // doc.setTextColor(0, 0, 0); // Set text color to black
        // doc.setFontSize(10); // Set font size for the footer

        // // Calculate the width of the text and page numbers
        // const textWidth = doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        // const pageWidth = doc.getStringUnitWidth(pageString) * doc.internal.getFontSize() / doc.internal.scaleFactor;

        // // Calculate the space needed for equal spacing
        // const spaceWidth = (availableWidth - textWidth - pageWidth) / 2;

        // // Add footer text and page numbers in the same row, justified with space between them
        // doc.text(footerX, footerY, footerText);
        // doc.text(footerX + spaceWidth + textWidth, footerY, pageString);
        doc.addImage(`${ServiceUrl?.showImageUrl}${projectDetails?.footer}`, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
      }

      // Get the total number of pages
      const totalNumPages = doc.internal.getNumberOfPages();

      // Loop through each page to add the footer with page numbers
      for (let i = 1; i <= totalNumPages; i++) {
        doc.setPage(i);
        addFooter(i);
      }
      const scaleFactor = 1.0; // Adjust this value as needed
      const pdfBlob = doc.output('blob', { scale: scaleFactor });

      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open a new window with the PDF URL

      if (isPrint) {
        const newWindow = window.open(pdfUrl, '_blank');

        // After the new window is opened, you can close the URL object
        URL.revokeObjectURL(pdfUrl);
        if (newWindow) {
          newWindow.onload = () => {
            setIsPrinting(false)
            setIsDownloading(false)
            // Wait for the window to load and then trigger the print dialog
            newWindow.print();
          };
        }
      }

      setIsPrinting(false)
      setIsDownloading(false)

      isPrint === false && doc.save('Contract_Work_Plan_Report.pdf');

    });
  };


useEffect(()=>{
  if (reportType === "project" && originalTableData && originalTableData?.length > 0) {
      setFilteredTableData(originalTableData)
  }
},[originalTableData])

  return (
    <>
      <div style={{ height: 'auto', width: "100%", padding: '30px', display: "flex", flexDirection: "column", gap: "20px" }}>

        <Grid container>

          <Grid item xs={12}>
            <Button
              size="small"
              sx={{ width: "100px" }}
              variant="contained"
              color="error"
              onClick={() => { window.history.back() }}
            >
              <Icon color="primary" fontSize="small">
                skip_previous
              </Icon>
              Back
            </Button>
          </Grid>
        </Grid>
        <SimpleCard>
          <ValidatorForm onSubmit={handleFormSubmit}>
            <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
              <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>Work Plan (Contract)</b></Grid>
            </Grid>
            <Grid container spacing={3} >
              <Grid item xs={12}>
                <RadioGroup
                  row
                  name="reportType"
                  value={reportType}
                  onChange={(e) => { setReportType(e.target.value); setOriginalTableData();setFilteredTableData();e.target.value==="project"&&setConstructionNameList();e.target.value==="project"&&setState({project_location_id:null});setState({...state,constructionname_id:null,project_location_id:null}) }}
                >&nbsp;&nbsp;
                 <FormControlLabel
                    label="Construction Name Level"
                    value="construction"
                    control={<Radio size="small" color="secondary" />}
                    sx={{ height: 20 }}
                  />
                  <FormControlLabel
                    label="Project Level"
                    value="project"
                    control={<Radio size="small" color="secondary" />}
                    sx={{ mr: 3, height: 20 }}
                  />
                 
                </RadioGroup>

              </Grid>
              <Grid item sm={4} xs={12}>
                <DecoratedTextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Project ID"
                  id="project_location_id"
                  variant="outlined"
                  name="project_location_id"
                  value={state?.project_location_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Project ID is required"]}
                >
                  {reportsProjectLocationList?.map((item) => (
                    <MenuItem value={item?.project_location_id} key={item?.project_id}>
                      {item?.project_id}
                    </MenuItem>
                  ))}
                </DecoratedTextField>
              </Grid>
             {reportType==="construction"&&
             <>
             <Grid item sm={4} xs={12}>
                <DecoratedTextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Construction Name"
                  id="constructionname_id"
                  variant="outlined"
                  name="constructionname_id"
                  value={state?.constructionname_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Construction Name is required"]}
                >
                  {constructionNameList?.map((item) => (
                    <MenuItem value={item?.constructionname_id} key={item?.constructionname_id}>
                      {item?.constructionName}
                    </MenuItem>
                  ))}
                </DecoratedTextField>
              </Grid>
               <Grid item sm={4} xs={12}>
               <DecoratedTextField
                 select
                 size="small"
                 fullWidth
                 sx={{ mb: 3 }}
                 label="BOQ Name"
                 id="boq_name"
                 variant="outlined"
                 name="boq_name"
                 value={state?.boq_name}
                 onChange={handleChange}
                 validators={["required"]}
                 errorMessages={["BOQ Name is required"]}
               >
                 {boqList?.map((item) => (
                   <MenuItem value={item} key={item}>
                     {item}
                   </MenuItem>
                 ))}
               </DecoratedTextField>
             </Grid>
             <Grid item sm={4} xs={12}>
               <DecoratedTextField
                 select
                 size="small"
                 fullWidth
                 sx={{ mb: 3 }}
                 label="Work Plan Date"
                 id="workplan_date"
                 variant="outlined"
                 name="workplan_date"
                 value={state?.workplan_date}
                 onChange={handleChange}
                 validators={["required"]}
                 errorMessages={["Work Plan Date is required"]}
               >
                 {workPlanDates?.map((item) => (
                   <MenuItem value={item} key={item}>
                     {moment(item)?.format("DD-MM-YYYY")}
                   </MenuItem>
                 ))}
               </DecoratedTextField>
             </Grid>
             <Grid item sm={4} xs={12}>
               <DecoratedTextField
                 select
                 size="small"
                 fullWidth
                 sx={{ mb: 3 }}
                 label="Work Plan No."
                 id="workplan_no"
                 variant="outlined"
                 name="workplan_no"
                 value={state?.workplan_no}
                 onChange={handleChange}
                 validators={["required"]}
                 errorMessages={["Work Plan No. is required"]}
               >
                 {workPlanNumbers?.map((item) => (
                   <MenuItem value={item} key={item}>
                     {item}
                   </MenuItem>
                 ))}
               </DecoratedTextField>
             </Grid>
             </>
              }

              <Grid item sm={4} xs={12}>


                <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>

              </Grid>

            </Grid>

          </ValidatorForm>
        </SimpleCard>
        {filteredTableData && filteredTableData?.length > 0 && 
        <Grid container>
          <Grid xs={12} md={6}>
            {checkValidation(projectDetails?.header)===false&&<FormLabel sx={{color:"#ff9800"}} color="warning">! Please upload header and footer for download/print</FormLabel>}
            </Grid>
          <Grid item xs={12} md={6}>
            <Box component="span" display="flex" justifyContent="flex-end">
              <Button
                size="small"
                disabled={projectDetails?.header?false:true}
                sx={{ width: "130px", marginLeft: 4 }}
                variant="contained"
                color="warning"
                startIcon={<CloudDownload />}
                onClick={() => { handleReportDownload(false) }}
              >
                Download
              </Button>
              <Button
                size="small"
                disabled={projectDetails?.header?false:true}
                sx={{ width: "100px", marginLeft: 2 }}
                variant="contained"
                color="primary"
                startIcon={<Print />}
                onClick={() => { handleReportDownload(true) }}
              >
                Print
              </Button>
            </Box>
          </Grid>

        </Grid>}
        {filteredTableData && filteredTableData?.length > 0 && <Card sx={{ padding: 3 }}>

          <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
            <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
              <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
                <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${projectDetails?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />

              </div>

              <Grid container >
                <Grid item xs={12} align='center' >
                  <FormLabel sx={{ fontSize: 22 }} ><b>CONTRACT WORK PLAN</b></FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{projectDetails?.project_name}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Client: </b>{projectDetails?.company}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project ID: </b>{projectDetails?.project_id}</FormLabel>
                </Grid>


              </Grid>
            </div>

            <div id="originalTableData">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead style={{ backgroundColor: '#87CEEB' }}>
                    <TableRow>
                      <TableCell>SL No.</TableCell>
                      <TableCell>Stage Name</TableCell>
                      {reportType==="project"&&<TableCell>BOQ Name</TableCell>}
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                      <TableCell>Remarks</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      filteredTableData?.map((section) => (
                        <React.Fragment key={section.constructionName}>
                          <TableRow>
                            <TableCell colSpan={5}><b>{section.constructionName}</b></TableCell>
                          </TableRow>
                          {section.data.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{index+1}</TableCell>
                              <TableCell>{row?.stage_name}</TableCell>
                              {reportType==="project"&&<TableCell>{row?.boq_name}</TableCell>}
                              <TableCell>{moment(row?.start_date).format("DD-MM-YYYY")}</TableCell>
                              <TableCell>{moment(row?.end_date).format("DD-MM-YYYY")}</TableCell>
                              <TableCell>{row?.remarks}</TableCell>
                            </TableRow>
                          ))}

                        </React.Fragment>
                      ))}

                  </TableBody>
                </Table>
              </TableContainer>

            </div>
          </div>
        </Card>}
       
      </div>
      {loading && (
        <Loader
          show={loading}
        />
      )}
      {(isPrinting || isDownloading) && (
        <Loader
          show={isPrinting ? isPrinting : isDownloading}
          text="Processing...Please wait."
        />
      )}

    </>
  )
}