import { AppConstants } from 'app/utils/AppConstants';
import { ServiceUrl } from 'app/utils/Constants';
import axios from 'axios';
import { GET_CONSTRUCTION_TYPE_RABILL } from './RABillActions';
import { UserRole } from 'app/utils/ProjectStatus';
import { getCurrentRole } from 'app/utils/utils';


export const GET_ALL_CLASSIFICATIONS_CONSTRUCTION = "GET_ALL_CLASSIFICATIONS_CONSTRUCTION"
export const GET_CONSTRUCTION_TYPE = 'GET_CONSTRUCTION_TYPE';
export const GET_PROJECT_LOCATION = "GET_PROJECT_LOCATION"
export const GET_CONSTRUCTION_NAME_LIST = 'GET_CONSTRUCTION_NAME_LIST';
export const CONSTRUCTION_TYPE_LIST_PBOQ = 'CONSTRUCTION_TYPE_LIST_PBOQ';
export const GET_ALL_BASELOCATIONS_CONSTRUCTION = "GET_ALL_BASELOCATIONS_CONSTRUCTION"
export const GET_CONSTRUCTION_TYPE_IMPLEMENTATION = "GET_CONSTRUCTION_TYPE_IMPLEMENTATION";

export const getAllConstructionTypeList = (showLoading, hideLoading, showToast, type = "construction") => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoading !== false && showLoading()
  axios.post(ServiceUrl.getAllConstructionTypeList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoading !== false && hideLoading()
    if (res?.status < 300) {
      var responseData = res?.data?.sort(function (a, b) {
        const nameA = a.constructiontype_name.toUpperCase();
        const nameB = b.constructiontype_name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      if (type === "construction") {
        dispatch({
          type: GET_CONSTRUCTION_TYPE,
          payload: responseData,
        });
      } else {
        dispatch({
          type: GET_CONSTRUCTION_TYPE_IMPLEMENTATION,
          payload: responseData,
        });
      }

    } else {
      showToast(res?.data?.message)
    }
  }).catch(err => {
    hideLoading !== false && hideLoading()
    showToast(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong)
  });
};
export const getAllConstructionTypeListForPBOQ = (id, showLoading, hideLoading, showToast, type = "construction") => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoading !== false && showLoading()
  axios.post(`/api/project/construction/${id}${ServiceUrl.getAllConstructionTypeListForPBOQ}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoading !== false && hideLoading()
    if (res?.status < 300 && res?.data?.success) {

      if (type === 'raBill') {
        dispatch({
          type: GET_CONSTRUCTION_TYPE_RABILL,
          payload: res?.data?.obj,
        });
      } else {
        dispatch({
          type: CONSTRUCTION_TYPE_LIST_PBOQ,
          payload: res?.data?.obj,
        });
      }
    } else {
      if (type === 'raBill') {
        dispatch({
          type: GET_CONSTRUCTION_TYPE_RABILL,
          payload: [],
        });
      } else {
        dispatch({
          type: CONSTRUCTION_TYPE_LIST_PBOQ,
          payload: []
        });
      }
      showToast('info', res?.data?.message)
    }
  }).catch(err => {
    hideLoading !== false && hideLoading()
    console.log(err)
    showToast(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong)
  });
};


export const getAllProjectLocationListForConstruction = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getAllProjectLocationListForProjectBOQ + `?role_id=${getCurrentRole()}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    if (hideLoader) {
      hideLoader()
    }
    if (res?.status < 300) {
      dispatch({
        type: GET_PROJECT_LOCATION,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }

  }).catch(err => {
    if (hideLoader) {
      hideLoader()
    }
    showToast(AppConstants.somethingWentWrong)
  });
};

export const getAllConstructionNameList = (constructiontype_id, projectlocation_id, showLoading, hideLoading, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  if (showLoading) {
    showLoading()
  }
  axios.post(`/api/project/construction/${constructiontype_id}/${projectlocation_id}/${getCurrentRole()}${ServiceUrl.getAllConstructionNameList}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoading !== false && hideLoading()
    if (res?.status < 300) {
      dispatch({
        type: GET_CONSTRUCTION_NAME_LIST,
        payload: res?.data,
      });
    } else {
      showToast(res?.message ?? res?.data?.message)
    }
  }).catch(err => {
    if (hideLoading) {
      hideLoading()
    }
    showToast(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong)
  })
};

export const getAllConstructionNameListForProjectBOQ = (constructiontype_id, projectlocation_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/projectBOQ/${constructiontype_id}/${projectlocation_id}${ServiceUrl.getAllConstructionNameListForProjectBOQ}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const saveOrUpdateConstructionName = (construction) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateConstructionName, construction, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
}

export const getBuiltUpItemsIdsByProjectLocationItemCodes = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getBuiltUpItemsIdsByProjectLocationItemCodes, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
}

export const getAllBuildUpItemistForConstructionNameBOQ = (project_location_id, chapter_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/construction/${project_location_id}/${chapter_id}${ServiceUrl.getAllBuildUpItemistForConstructionNameBOQ}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdateConstructionType = (construction) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateConstructionType, construction, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
}




export const getAllBMLItemistForConstructionNameBOQ = (project_location_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/${project_location_id}${ServiceUrl.getAllBMLItemistForConstructionNameBOQ}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

// export const getAllBuiltUpItemListByisBuiltupitemFalse = () => {
//   const accessToken = window.localStorage.getItem('accessToken')
//   return axios.get(ServiceUrl.getAllBuiltUpItemListByisBuiltupitemFalse,{},{headers: {
//     'accept': '*/*',
//     'Authorization': `Bearer ${accessToken}`
//  }})
// };
export const getAllBuiltUpItemListByisBuiltupitemFalse = () => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios({
    url: ServiceUrl.getAllBuiltUpItemListByisBuiltupitemFalse,
    method: 'GET',
    headers: { 'Authorization': `Bearer ${accessToken}` }
  })
}

//getAllBuiltUpItemListByisBuiltupitemFalse


export const getAllConstructionNameBOQListByConstructionNameId = (constructionname_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/construction/${constructionname_id}${ServiceUrl.getAllConstructionNameBOQListByConstructionNameId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const deleteConstructionNameBOQDetails = (constructionnameboq_details_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/project/construction/${constructionnameboq_details_id}${ServiceUrl.deleteConstructionNameBOQDetails}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const deleteCIndexWeightageAndRate = (constructionname_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/project/construction/${constructionname_id}${ServiceUrl.deleteCostIndexWeightageAndRate}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteConstructionNameById = (constructionname_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/construction/${constructionname_id}${ServiceUrl.deleteConstructionNameById}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteConstructionNameDOM = (cnboqdom_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/project/construction/${cnboqdom_id}${ServiceUrl.deleteConstructionNameDOM}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdateConstructionNameBOQ = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateConstructionNameBOQ, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const calculateLabourAndMaterialRates = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.calculateLabourAndMaterialRates, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdateProjectBOQ = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateProjectBOQ, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveConstructionNameBOQDOM = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveConstructionNameBOQDOM, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllProjectBOQListByCriteria = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getAllProjectBOQListByCriteria, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllConstructionNameBOQDetailsListByConstructionnameboqId = (cnboqdom_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/construction/${cnboqdom_id}${ServiceUrl.getAllConstructionNameBOQDetailsListByConstructionnameboqId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getDataForEGP = (project_location_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/egp/${project_location_id}${ServiceUrl.getDataForEGP}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getProjectLocationItemDetails = ({ project_location_id, construction_type_id, item_code }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/contractDetails/${project_location_id}/${construction_type_id}/${item_code}/getProjectLocationItemDetails`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteProjectBOQ = (projectboq_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/project/projectBOQ/${projectboq_id}${ServiceUrl.deleteProjectBOQ}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteAllProjectBOQ = (project_location_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/project/projectBOQ/${project_location_id}${ServiceUrl.deleteAllProjectBOQ}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const updateTenderDetails = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/contractDetails${ServiceUrl.updateTenderDetails}`, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`

    }
  })
};


export const uploadDOMManageExcel = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.uploadDOMExcel, params, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const downloadDOMManageExcel = (year) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios({
    url: ServiceUrl.downloadDOMExcel,
    method: 'GET',
    headers: { 'Authorization': `Bearer ${accessToken}` },
    responseType: 'blob',
  })
}

export const getAllClassificationsListForConstruction = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader !== false && showLoader()
  axios.post(ServiceUrl.getAllClassificationList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader !== false && hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_ALL_CLASSIFICATIONS_CONSTRUCTION,
        payload: res?.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(err => {
    hideLoader !== false && hideLoader()
    showToast(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong)
  });
};


export const getAllBaseLocationsListForConstruction = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader !== false && showLoader()
  axios.post(ServiceUrl.getAllBaseLocationList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_ALL_BASELOCATIONS_CONSTRUCTION,
        payload: res?.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(err => {
    hideLoader()
    showToast(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong)
  });
};

export const getUpdatedRateListForCopyCNBOQ = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getUpdatedRateListForCopyCNBOQ, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getUpdatedRateListForCopyCNBOQForProjectLocation = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getUpdatedRateListForCopyCNBOQForProjectLocation, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
