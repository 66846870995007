import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CloudDownload, ErrorOutline, Print } from "@mui/icons-material";
import { Box, Button, Card, FormLabel, Grid, Icon, MenuItem, TextField, styled } from "@mui/material";
import { Breadcrumb, Loader } from "app/components";
import { getAllProjectLocationListForReports } from "app/redux/actions/ProjectLocationActions";
import { getContractDetailsReport, getContractWorkPlanReport, getProjectBoqContractReport } from "app/redux/actions/ReportsAction";
import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from 'app/utils/Constants';
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation, currencyFormat, formatDate, formatNumberWithCommasAndDecimals, getTwoDigitDecimal } from "app/utils/utils";
import { SimpleCard } from "components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";

const styles = {
  tableCell: {
    textAlign: "center"
  },
  subHeader: {
    textAlign: "center",
    fontWeight: 700
  }

}
const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));




export const ContractDetailsReport = () => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isDownloading, setIsDownloading] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [projectDetails, setProjectDetails] = useState({})
  const [headerFooterImg, setHeaderFooterImg] = useState()
  const { reportsProjectLocationList } = useSelector((state) => state.projectLocation);
  const [totalAmount, setTotalAmount] = useState()

  const [tableData, setTableData] = useState()
    ;
  const [state, setState] = useState({
    project_location_id: '',
  })


  useEffect(() => {
    console.log("hostname:", window.location.hostname)
    dispatch(getAllProjectLocationListForReports(showLoader, hideLoader, showToast))
  }, []);
  const showLoader = () => {
    setLoading(true)
  }
  const showToast = (msg) => {
    const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  }

  const hideLoader = () => {
    setLoading(false)
  }
  const handleChange = (event, source) => {

    if (event.target.name === "project_location_id") {
      setTableData([])
      setProjectDetails({})
      setHeaderFooterImg()
      const filteredArray = reportsProjectLocationList.filter(project => {
        return project.project_location_id === event.target.value
      });
      state.project_name = filteredArray[0].project_name
      state.project_location_id = filteredArray[0].project_location_id
      state.project_id = filteredArray[0].project_id

      setState({ ...state, [event.target.name]: event.target.value });

    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  }


  const handleFormSubmit = () => {
    setLoading(true)
    getContractDetailsReport({ project_location_id: state?.project_location_id })
      .then((res) => {
        setLoading(false)
        if (res?.status < 300) {
          if (res?.data?.obj?.item) {
            getContractProjectBoQReport(res?.data?.obj?.item)
          }
        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);
        }
      }).catch(error => {
        setLoading(false)
        const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);
      })
    }

    const getContractProjectBoQReport = (item) => {
        setLoading(true)
        setTableData([])
        getProjectBoqContractReport({ project_location_id: state?.project_location_id }).then((res) => {
            setLoading(false)
            if (res?.status < 300) {
              if (res?.data?.obj && res?.data?.success) {
                if (res?.data?.obj && res?.data?.obj?.item && res?.data?.obj?.item?.construcion_type?.length > 0) {
                    const tableDataList = res?.data?.obj?.item?.construcion_type?.map((elem) => {
                      const construcion_type = elem?.construcion_type
                      const dataList = elem?.item_list?.map((item, index) => {
                        return {
                          sl_no: index + 1,
                          bsr_ref_no: item?.item_code,
                          item_description: item?.item_description,
                          quantity: item?.quantity,
                          unit: item?.unit_name,
                          rate: item?.contract_rate,
                          amount: getTwoDigitDecimal(item?.amount),
                          workcharge_amount: item?.workcharge_percent ?? 0,
                          cont_percentage: item?.contingency_percentage ?? 0,
                          cont_amount: item?.contingency_amount ?? 0,
                        }
                      })
                      const obj = {
                        constructionType: construcion_type,
                        data: dataList
                      }
                      return obj
                    })
                    const TotalAmount = tableDataList.reduce((accumulator, constructionTypeData) => {
                      const constructionTypeTotal = constructionTypeData?.data?.reduce((subAccumulator, itemData) => {
                        return subAccumulator + getTwoDigitDecimal(itemData?.amount);
                      }, 0);
                      return accumulator + constructionTypeTotal;
                    }, 0);
    
                    setTotalAmount(getTwoDigitDecimal(TotalAmount))
                    setTableData(tableDataList)
                    
                  setProjectDetails({
                    ...item,
                    workcharge_amount: res?.data?.obj?.item?.construcion_type?.[0]?.item_list?.[0]?.workcharge_percent ?? 0,
                    cont_percentage: res?.data?.obj?.item?.construcion_type?.[0]?.item_list?.[0]?.contingency_percentage ?? 0,
                    cont_amount: res?.data?.obj?.item?.construcion_type?.[0]?.item_list?.[0]?.contingency_amount ?? 0
                  })
                  setHeaderFooterImg({
                    header_img: res?.data?.obj?.header_logo,
                    footer_img: res?.data?.obj?.footer_logo
                  })
                }
              }
              else {
                const key = enqueueSnackbar(res?.data?.message, { variant: 'info' });
                SnackBarProperty.info(key, closeSnackbar);
              }
            }
            else {
              const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
              SnackBarProperty.error(key, closeSnackbar);
    
            }
          }).catch(error => {
            setLoading(false)
            const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
            SnackBarProperty.error(key, closeSnackbar);
    
          })
      }

    //   function mergeByConstructionType(data) {
    //     console.log("data", data)
    //     const result = data.reduce((acc, curr) => {
    //       console.log("current", curr)
    //       const constructionType = curr.construcion_type;
    //       const totalAmount = curr.item_list.reduce((sum, item) => sum + item.amount, 0);

    //       // Create a new object with construction_type and total
    //       acc.push({ description: `Total for ${constructionType} (Nu)`, total: totalAmount });
    //       return acc;
    //     }, []);

    //     return result;
    //   }

  const handleReportDownload = (isPrint) => {

    if (isPrint) {
      setIsPrinting(true)
      setIsDownloading(false)
    }
    else {
      setIsPrinting(false)
      setIsDownloading(true)
    }
    const doc = new jsPDF();
    const contentHeight = 150;
    const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image

    doc.setFontSize(12); // Set font size


    html2canvas(headerImage, {
      useCORS: true, // Add this line if the image is from a different domain
      allowTaint: true, // Add this line if the image is from a different domain
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // A4 dimensions in mm
      const pdfWidth = 210;
      const pdfHeight = 297;

      // Calculate aspect ratio
      const aspectRatio = imgWidth / imgHeight;

      // Calculate scaled dimensions to fit within A4 while maintaining aspect ratio
      let scaledWidth = pdfWidth;
      let scaledHeight = pdfWidth / aspectRatio;

      if (scaledHeight > pdfHeight) {
          scaledHeight = pdfHeight;
          scaledWidth = pdfHeight * aspectRatio;
      }
      //Insert the header image into the PDF
      const topBorderPadding = 10; // Adjust the padding value as needed
      doc.setDrawColor(255, 255, 255); // Black color for border
      doc.setLineWidth(topBorderPadding); // Border width (padding)

      // Draw the top border (padding)
      // doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);

      // Insert the header image into the PDF
      doc.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);
      const textX = 15;
      const textY = scaledHeight + 10;// Adjust the value for vertical separation

      // Add the text content below the header image
      doc.setFont('helvetica', 'bold'); // Set font to bold
      doc.setFontSize(12); // Set font size
      doc.text("CONTRACT DETAILS", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("CONTRACT DETAILS") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY);
      
      const firstColTextWidth = doc.getStringUnitWidth("Performance Security Amount (Nu):") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const secondColTextWidth = doc.getStringUnitWidth("Invitation Reference Number:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      
      doc.setFontSize(8); // Set font size
      doc.text("Project Location:", textX, textY + 10);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.project_location ?? "", textX + firstColTextWidth-15, textY + 10);
      doc.setFont('helvetica', 'bold');
      doc.text("Project Name:", textX, textY + 15);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.project_name ?? "", textX + firstColTextWidth-15, textY + 15);
      doc.setFont('helvetica', 'bold');
      doc.text("Project ID:", textX, textY + 20);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.project_id ?? "", textX + firstColTextWidth-15, textY + 20);
      doc.setFont('helvetica', 'bold');
      doc.text("Tender ID:", textX, textY + 25);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.project_id ?? "", textX + firstColTextWidth-15, textY + 25);
      doc.setFont('helvetica', 'bold');
      doc.text("Invitation Reference Number:", textX, textY + 30);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.invitation_reference_no ?? "", textX + firstColTextWidth-15, textY + 30);
      doc.setFont('helvetica', 'bold');
      doc.text("Company:", textX, textY + 35);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.company ?? "", textX + firstColTextWidth-15, textY + 35);
      // doc.setFont('helvetica', 'bold');
      // doc.text("Performance Security Amount (Nu):", textX, textY + 35);
      // doc.setFont('helvetica', 'normal');
      // doc.text(projectDetails?.invitation_reference_no ?? "", textX + firstColTextWidth-15, textY + 35);
      

      

      
      doc.setFont('helvetica', 'bold');
      doc.text("CDB No.:", textX, textY + 40);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.cdb_no ?? "", textX + firstColTextWidth-15, textY + 40);
      doc.setFont('helvetica', 'bold');
      doc.text("TPN No.:", (doc.internal.pageSize.getWidth() / 2), textY + 40);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.tpn_no ?? "", (doc.internal.pageSize.getWidth() / 2) + secondColTextWidth -10, textY + 40);
      doc.setFont('helvetica', 'bold');
      doc.text("Contact No.:", textX, textY + 45);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.mobile_no ?? ""}`, textX + firstColTextWidth-15, textY + 45);
      doc.setFont('helvetica', 'bold');
      doc.text("Email:", (doc.internal.pageSize.getWidth() / 2), textY + 45);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.email ?? "", (doc.internal.pageSize.getWidth() / 2) + secondColTextWidth -10, textY + 45);
      
      doc.setFont('helvetica', 'bold');
      doc.text("Work Order No.:", textX, textY + 50);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.workorder_no ?? "", textX + firstColTextWidth-15, textY + 50);
      doc.setFont('helvetica', 'bold');

      doc.text("Contract Amount:", textX, textY + 55);
      doc.setFont('helvetica', 'normal');
      doc.text(`${formatNumberWithCommasAndDecimals(projectDetails?.initial_contract_amount ?? 0)}`, textX + firstColTextWidth-15, textY + 55);
      doc.setFont('helvetica', 'bold');
      doc.text("Contract Signed Date:", (doc.internal.pageSize.getWidth() / 2), textY + 55);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.contract_signed_date ? formatDate(projectDetails?.contract_signed_date) : "N/A", (doc.internal.pageSize.getWidth() / 2) + secondColTextWidth-10, textY + 55);

      doc.setFont('helvetica', 'bold');
      doc.text("Work Start Date:", textX, textY + 60);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.work_start_date ? formatDate(projectDetails?.work_start_date) : "", textX + firstColTextWidth-15, textY + 60);
      doc.setFont('helvetica', 'bold');
      doc.text("Work End Date:", (doc.internal.pageSize.getWidth() / 2), textY + 60);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.work_end_date ? formatDate(projectDetails?.work_end_date) : "", (doc.internal.pageSize.getWidth() / 2) + secondColTextWidth -10, textY + 60);

      doc.setFont('helvetica', 'bold');
      doc.text("Extension Order No.:", textX, textY + 65);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.contract_extension_no === "" ? "N/A" : projectDetails?.contract_extension_no ?? "N/A", textX + firstColTextWidth-15, textY + 65);
      doc.setFont('helvetica', 'bold');
      doc.text("Extension Order Date:", (doc.internal.pageSize.getWidth() / 2), textY + 65);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.contract_extension_date ? formatDate(projectDetails?.contract_extension_date) : "N/A", (doc.internal.pageSize.getWidth() / 2) + secondColTextWidth -10, textY + 65);

      doc.setFont('helvetica', 'bold');
      doc.text("Work Completion Date:", textX, textY + 70);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.work_completion_date ? formatDate(projectDetails?.work_completion_date) : "N/A", textX + firstColTextWidth-15, textY + 70);

      doc.setFont('helvetica', 'bold');
      doc.text("Mobilization(%):", textX, textY + 75);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.mobilization_percentage === "" ? "N/A" : projectDetails?.mobilization_percentage ?? "N/A"}`, textX + firstColTextWidth-15, textY + 75);
      doc.setFont('helvetica', 'bold');
      doc.text("Performance Security(%):", (doc.internal.pageSize.getWidth() / 2), textY + 75);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.performance_bond_percentage ? formatNumberWithCommasAndDecimals(projectDetails?.performance_bond_percentage) : "N/A"}`, (doc.internal.pageSize.getWidth() / 2) + secondColTextWidth -10, textY + 75);


      doc.setFont('helvetica', 'bold');
      doc.text("Performance Security Amount (Nu):", textX, textY + 80);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.performance_bond_amount ? formatNumberWithCommasAndDecimals(projectDetails?.performance_bond_amount) : "N/A"}`, textX + firstColTextWidth-15, textY + 80);
      doc.setFont('helvetica', 'bold');
      doc.text("Performance Validity:", (doc.internal.pageSize.getWidth() / 2), textY + 80);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.performance_bond_validity ? formatDate(projectDetails?.performance_bond_validity) : "N/A"}`, (doc.internal.pageSize.getWidth() / 2) + secondColTextWidth -10, textY + 80);

      doc.setFont('helvetica', 'bold');
      doc.text("LD(%):", textX, textY + 85);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.ld_percentage === "" ? "N/A" : projectDetails?.ld_percentage ?? "N/A"}`, textX + firstColTextWidth-15, textY + 85);
      doc.setFont('helvetica', 'bold');
      doc.text("LD Amount Limit(%)", (doc.internal.pageSize.getWidth() / 2), textY + 85);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.penalty_amount_limit ? projectDetails?.penalty_amount_limit : "N/A"}`, (doc.internal.pageSize.getWidth() / 2) + secondColTextWidth -10, textY + 85);


      doc.setFont('helvetica', 'bold');
      doc.text("TDS(%):", textX, textY + 90);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.tds_amount === "" ? "N/A" : projectDetails?.tds_amount ?? "N/A"}`, textX + firstColTextWidth-15, textY + 90);
      doc.setFont('helvetica', 'bold');
      doc.text("Variance(%):", (doc.internal.pageSize.getWidth() / 2), textY + 90);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.variance ? projectDetails?.variance : "N/A"}`, (doc.internal.pageSize.getWidth() / 2) + secondColTextWidth -10, textY + 90);

      doc.setFont('helvetica', 'bold');
      doc.text("Excess of Initial Contract Amount(%):", textX, textY + 95);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.excess_initial_contract === "" ? "N/A" : formatNumberWithCommasAndDecimals(projectDetails?.excess_initial_contract) ?? "N/A"}`, textX + firstColTextWidth-15, textY + 95);
      doc.setFont('helvetica', 'bold');
      doc.text("Retention(%):", (doc.internal.pageSize.getWidth() / 2), textY + 95);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.retention ? projectDetails?.retention : "N/A"}`, (doc.internal.pageSize.getWidth() / 2) + secondColTextWidth -10, textY + 95);


      doc.setFont('helvetica', 'bold'); // Set font to bold
      doc.setFontSize(10);
      doc.text("Price Adjustment Details", textX, textY + 105);
      doc.setFontSize(8);
      const monthTextWidth=doc.getStringUnitWidth("Price Adjustment Month:") * doc.internal.getFontSize() / doc.internal.scaleFactor
      doc.setFont('helvetica', 'bold');
      doc.text("Price Adjustment Month:", textX, textY + 115);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.price_adjustment_month === "" ? "N/A" : projectDetails?.price_adjustment_month ?? "N/A"}`, textX + monthTextWidth+5, textY + 115);
      


      const bottomLeftTextWidth=doc.getStringUnitWidth("Material Variance:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const bottomMiddleTextWidth=doc.getStringUnitWidth("Material Constant:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const bottomRightTextWidth=doc.getStringUnitWidth("Labour Index (Lo):") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      doc.setFont('helvetica', 'bold');
      doc.text("Labour Variance:", textX, textY + 120);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.labour_variance ? projectDetails?.labour_variance : "N/A"}`, textX + bottomLeftTextWidth+5, textY + 120);
      doc.setFont('helvetica', 'bold');
      doc.text("Labour Constant:", ((doc.internal.pageSize.getWidth() / 3)*1), textY + 120);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.labour_constant ? projectDetails?.labour_constant : "N/A"}`, ((doc.internal.pageSize.getWidth() / 3)*1)+bottomMiddleTextWidth+5 , textY + 120);
      doc.setFont('helvetica', 'bold');
      doc.text("Labour Index (Lo):", ((doc.internal.pageSize.getWidth() / 3)*2), textY + 120);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.labour_index ? projectDetails?.labour_index : "N/A"}`, ((doc.internal.pageSize.getWidth() / 3)*2)+bottomRightTextWidth+5, textY + 120);

      doc.setFont('helvetica', 'bold');
      doc.text("Material Variance:", textX, textY + 125);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.material_variance ? projectDetails?.material_variance : "N/A"}`, textX + bottomLeftTextWidth+5, textY + 125);
      doc.setFont('helvetica', 'bold');
      doc.text("Material Constant:", ((doc.internal.pageSize.getWidth() / 3)*1), textY + 125);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.material_constant ? projectDetails?.material_constant : "N/A"}`, ((doc.internal.pageSize.getWidth() / 3)*1)+bottomMiddleTextWidth+5 , textY + 125);
      doc.setFont('helvetica', 'bold');
      doc.text("Material Index (Mo):", ((doc.internal.pageSize.getWidth() / 3)*2), textY + 125);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.material_index ? projectDetails?.material_index : "N/A"}`, ((doc.internal.pageSize.getWidth() / 3)*2)+bottomRightTextWidth+5, textY + 125);
      
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(12);
            doc.text("CONTRACT BOQ RATES", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("PROJECT BOQ REPORT") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY + 145);            
      
            const headerSectionHeight = textY + 155; // Adjust the value as needed
      
            // Calculate the starting Y-coordinate for the table
            const tableStartY = headerSectionHeight 
            // + 35;
            doc.setFont('helvetica', 'normal');
      
            function transformData(data) {
              const transformedData = [];
              data.forEach((section) => {
                // Create a row for the section header value (spanning across all 8 columns)
                const headerRow = [{ content: section.constructionType, colSpan: 7, styles: { fontStyle: "bold", fontSize: 8 } }];
                transformedData.push(headerRow);
      
                // Loop through the data of the section and create rows for each object in the data
                section.data.forEach((row, index) => {
                  console.log(`row ${index}:`, row)
                  const rowData = Object.values(row); // Extract values of each object
      
                  transformedData.push([
                    { content: index + 1, styles: { fontSize: 8 } },
                    { content: row?.bsr_ref_no ?? "", styles: { fontSize: 8 } },
                    { content: row?.item_description ?? "", colSpan: 2, styles: { fontSize: 8 } },
                    { content: row?.quantity ?? "", styles: { fontSize: 8 } },
                    { content: row?.unit ?? "", styles: { fontSize: 8 } },
                    { content: row?.rate ?? "", styles: { fontSize: 8 } },
                    { content: currencyFormat(row?.amount), styles: { fontSize: 8 } },
                  ]);
                  console.log("transformed data:", transformData)
                });
      
              });
      
              return transformedData;
            }
            const transformedData = transformData(tableData);
              transformedData.push([{ content: "Sub-total amount (Nu):", colSpan: 7, styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }, { content: formatNumberWithCommasAndDecimals(totalAmount), styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }])
              transformedData.push([
                { content: "Work Charge Amount (Nu):", colSpan: 7, styles: { halign: "right", fontStyle: "bold", fontSize: 8 } },
                { content: formatNumberWithCommasAndDecimals(parseFloat(projectDetails?.workcharge_amount ?? 0)?.toFixed(2)), styles: { halign: "right", fontSize: 8 } }
              ])
              transformedData.push([
                { content: `Contingency Percentage (%): ${parseFloat(projectDetails?.cont_percentage ?? 0)?.toFixed(2)}`, colSpan: 4, styles: { halign: "left", fontStyle: "bold", fontSize: 8 } },
                // { content: formatNumberWithCommasAndDecimals(parseFloat(projectDetails?.cont_percentage??0)?.toFixed(2)), styles: { halign: "left"}},
                { content: "Contingency Amount (Nu):", colSpan: 3, styles: { halign: "right", fontStyle: "bold", fontSize: 8 } },
                { content: formatNumberWithCommasAndDecimals(parseFloat(projectDetails?.cont_amount ?? 0)?.toFixed(2)), styles: { halign: "right", fontSize: 8 } },
              ])
              transformedData.push([
                { content: "Sub-Total Amount (Nu):", colSpan: 7, styles: { halign: "right", fontStyle: "bold", fontSize: 8 } },
                { content: formatNumberWithCommasAndDecimals(parseFloat((projectDetails?.workcharge_amount ?? 0) + (projectDetails?.cont_amount ?? 0))?.toFixed(2)), styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }
              ])
              transformedData.push([{ content: "Total Amount (Nu)", colSpan: 7, styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }, { content: formatNumberWithCommasAndDecimals(parseFloat((parseFloat((projectDetails?.workcharge_amount ?? 0) + (projectDetails?.cont_amount ?? 0))) + parseFloat(totalAmount))?.toFixed(2)), styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }])
      
      
            doc.autoTable({
              startY: tableStartY,
              head: [[
                { content: "SL NO.", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
                { content: "ITEM CODE", colSpan: 1, styles: { valign: "middle", halign: "left", cellPadding: 2, fontSize: 8 } },
                { content: "ITEM DESCRIPTION", colSpan: 2 , styles: { valign: "middle", halign: "left", cellPadding: 2, fontSize: 8 } },
                { content: "QUANTITY", colSpan: 1, styles: { valign: "middle", halign: "right", cellPadding: 2, fontSize: 8 } },
                { content: "UNIT", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
                { content: "RATE (Nu)", colSpan: 1, styles: { valign: "middle", halign: "right", cellPadding: 2, fontSize: 8 } },
                { content: "AMOUNT (Nu)", colSpan: 1, styles: { valign: "middle", halign: "right", cellPadding: 2, fontSize: 8 } }
              ]],
              body: transformedData, // Use the transformed data here
              theme: "grid",
              styles: {
                fillColor: [255, 255, 255], // Remove background color from the table
                lineColor: [0, 0, 0], // Set border color for main table
                lineWidth: 0.1, // Set border width for main table
                textColor: [0, 0, 0], // Set text color to black
                cellPadding: 2, // Add padding to cells
              },
              columnStyles: {
                2: { cellWidth: 50 }, // "ITEM DESCRIPTION" column width
                4: { halign: "right" },
                5: { halign: "left" }, // "RATE" column width
                6: { halign: "right" }, // "AMOUNT" column width
                7: { halign: "right" }, // "AMOUNT" column width
              },
            });
     
      function addFooter(pageNumber) {

        const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
        const footerX = 0; // Starting X position for the footer
        const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = 10;
        const availableWidth = pdfWidth - 2 * footerX;

        // doc.setTextColor(0, 0, 0); // Set text color to black
        // doc.setFontSize(10); // Set font size for the footer

        // // Calculate the width of the text and page numbers
        const textWidth = doc.internal.getFontSize() / doc.internal.scaleFactor;
        const pageWidth = doc.getStringUnitWidth(pageString) * doc.internal.getFontSize() / doc.internal.scaleFactor;

        // // Calculate the space needed for equal spacing
        const spaceWidth = (availableWidth - textWidth - pageWidth) / 2;

        // // Add footer text and page numbers in the same row, justified with space between them
        // doc.text(footerX, footerY, footerText);
        // doc.text(footerX + spaceWidth + textWidth, footerY, pageString);
        doc.addImage(`${ServiceUrl?.showImageUrl}${headerFooterImg?.footer_img}`, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')      }

      // Get the total number of pages
      const totalNumPages = doc.internal.getNumberOfPages();

      // Loop through each page to add the footer with page numbers
      for (let i = 1; i <= totalNumPages; i++) {
        doc.setPage(i);
        addFooter(i);
      }
      const scaleFactor = 1.0; // Adjust this value as needed
      const pdfBlob = doc.output('blob', { scale: scaleFactor });

      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open a new window with the PDF URL

      if (isPrint) {
        const newWindow = window.open(pdfUrl, '_blank');

        // After the new window is opened, you can close the URL object
        URL.revokeObjectURL(pdfUrl);
        if (newWindow) {
          newWindow.onload = () => {
            setIsPrinting(false)
            setIsDownloading(false)
            // Wait for the window to load and then trigger the print dialog
            newWindow.print();
          };
        }
      }

      setIsPrinting(false)
      setIsDownloading(false)

      isPrint === false && doc.save(`${projectDetails?.project_id ?? ""}_Contract_Details_Report.pdf`);

    });


  };

  return (
    <>

      <div style={{ height: 'auto', width: "100%", padding: '30px', display: "flex", flexDirection: "column", gap: "20px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Button
              size="small"
              sx={{ width: "100px" }}
              variant="contained"
              color="error"
              onClick={() => { window.history.back() }}
            >
              <Icon color="primary" fontSize="small">
                skip_previous
              </Icon>
              Back
            </Button>
          </Grid>
        </Grid>
        <SimpleCard>
          <ValidatorForm onSubmit={handleFormSubmit}>
            <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
              <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>Contract Details</b></Grid>
            </Grid>
            <Grid container spacing={3} >

              <Grid item sm={4} xs={12}>
                <DecoratedTextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Project ID"
                  id="project_location_id"
                  variant="outlined"
                  name="project_location_id"
                  value={state?.project_location_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Project ID is required"]}
                >
                  {reportsProjectLocationList?.map((item) => (
                    <MenuItem value={item?.project_location_id} key={item?.project_id}>
                      {item?.project_id}
                    </MenuItem>
                  ))}
                </DecoratedTextField>
              </Grid>

              <Grid item sm={4} xs={12}>


                <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>

              </Grid>

            </Grid>

          </ValidatorForm>
        </SimpleCard>
        {projectDetails && Object?.keys(projectDetails)?.length > 0 &&
          <Grid container>
            <Grid xs={12} md={6}>
              {checkValidation(headerFooterImg?.header_img) === false && <FormLabel sx={{ color: "#ff9800" }} color="warning">! Please upload header and footer for download/print</FormLabel>}
            </Grid>
            <Grid item xs={12} md={6}>
              <Box component="span" display="flex" justifyContent="flex-end">
                <Button
                  size="small"
                  disabled={headerFooterImg?.header_img ? false : true}
                  sx={{ width: "130px", marginLeft: 4 }}
                  variant="contained"
                  color="warning"
                  startIcon={<CloudDownload />}
                  onClick={() => { handleReportDownload(false) }}
                >
                  Download
                </Button>
                <Button
                  size="small"
                  disabled={headerFooterImg?.header_img ? false : true}
                  sx={{ width: "100px", marginLeft: 2 }}
                  variant="contained"
                  color="primary"
                  startIcon={<Print />}
                  onClick={() => { handleReportDownload(true) }}
                >
                  Print
                </Button>
              </Box>
            </Grid>

          </Grid>}
        {projectDetails && Object?.keys(projectDetails)?.length > 0 && <Card sx={{ padding: 3 }}>

          <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
            <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
              <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
                <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${headerFooterImg?.header_img}`} style={{ height: "150px", width: "auto" }} alt="img" />

              </div>

              <Grid container >
                <Grid item xs={12} align='center' >
                  <FormLabel sx={{ fontSize: 22 }} ><b>CONTRACT DETAILS</b></FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 4 }}>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 } }}><b>Project Location: </b>{projectDetails?.project_location ?? ""}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 } }}><b>Project Name: </b>{projectDetails?.project_name ?? ""}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 } }}><b>Project ID: </b>{projectDetails?.project_id ?? ""}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2, display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "flex-start", justifyContent: "space-between", gap: 2 }}>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "50%" }}><b>Tender ID: </b>{projectDetails?.tender_id ?? ""}</FormLabel>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "50%" }}><b>Invitation Reference Number: </b>{projectDetails?.invitation_reference_no ?? ""}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 } }}><b>Company: </b>{projectDetails?.company ?? ""}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2, display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "flex-start", justifyContent: "space-between", gap: 2 }}>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "50%" }}><b>CDB No: </b>{projectDetails?.cdb_no ?? ""}</FormLabel>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "50%" }}><b>TPN: </b>{projectDetails?.tpn_no ?? ""}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2, display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "flex-start", justifyContent: "space-between", gap: 2 }}>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "50%" }}><b>Contact No.: </b>{projectDetails?.mobile_no ?? ""}</FormLabel>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "50%" }}><b>Email: </b>{projectDetails?.email ?? ""}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 } }}><b>Work Order No.: </b>{projectDetails?.workorder_no ?? ""}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2, display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "flex-start", justifyContent: "space-between", gap: 2 }}>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "50%" }}><b>Contract Amount: </b>{projectDetails?.initial_contract_amount ?? ""}</FormLabel>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "50%" }}><b>Contract Signed Date: </b>{projectDetails?.contract_signed_date ? formatDate(projectDetails?.contract_signed_date) : ""}</FormLabel>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: 2, display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "flex-start", justifyContent: "space-between", gap: 2 }}>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "50%" }}><b>Work Start Date: </b>{formatDate(projectDetails?.work_start_date) ?? ""}</FormLabel>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "50%" }}><b>Work End Date: </b>{formatDate(projectDetails?.work_end_date) ?? ""}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2, display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "flex-start", justifyContent: "space-between", gap: 2 }}>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "50%" }}><b>Extension Order No.: </b>{projectDetails?.contract_extension_no === "" ? "N/A" : projectDetails?.contract_extension_no ?? "N/A"}</FormLabel>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "50%" }}><b>Extension Order Date: </b>{projectDetails?.contract_extension_date ? formatDate(projectDetails?.contract_extension_date) : "N/A"}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 } }}><b>Work Completion Date: </b>{projectDetails?.work_completion_date ? formatDate(projectDetails?.work_completion_date) : "N/A"}</FormLabel>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: 2, display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "flex-start", justifyContent: "space-between", gap: 2 }}>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "50%" }}><b>Mobilization(%): </b>{projectDetails?.mobilization_percentage === "" ? "N/A" : projectDetails?.mobilization_percentage ?? "N/A"}</FormLabel>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "50%" }}><b>Performance Security(%): </b>{projectDetails?.performance_bond_percentage ? projectDetails?.performance_bond_percentage : "N/A"}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2, display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "flex-start", justifyContent: "space-between", gap: 2 }}>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "50%" }}><b>Performance Security Amount (Nu): </b>{projectDetails?.performance_bond_amount ? projectDetails?.performance_bond_amount : "N/A"}</FormLabel>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "50%" }}><b>Performance Validity: </b>{projectDetails?.performance_bond_validity ? formatDate(projectDetails?.performance_bond_validity) : "N/A"}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2, display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "flex-start", justifyContent: "space-between", gap: 2 }}>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "50%" }}><b>LD(%): </b>{projectDetails?.ld_percentage === "" ? "N/A" : projectDetails?.ld_percentage ?? "N/A"}</FormLabel>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "50%" }}><b>LD Amount Limit(%): </b>{projectDetails?.penalty_amount_limit ? projectDetails?.penalty_amount_limit : "N/A"}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2, display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "flex-start", justifyContent: "space-between", gap: 2 }}>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "50%" }}><b>TDS(%): </b>{projectDetails?.tds_amount === "" ? "N/A" : projectDetails?.tds_amount ?? "N/A"}</FormLabel>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "50%" }}><b>Variance(%): </b>{projectDetails?.variance ? projectDetails?.variance : "N/A"}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2, display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "flex-start", justifyContent: "space-between", gap: 2 }}>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "50%" }}><b>Excess of Initial Contract Amount(%): </b>{projectDetails?.excess_initial_contract === "" ? "N/A" : projectDetails?.excess_initial_contract ?? "N/A"}</FormLabel>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "50%" }}><b>Retention(%): </b>{projectDetails?.retention ? projectDetails?.retention : "N/A"}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2, display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "flex-start", justifyContent: "space-between", gap: 2 }}>
                  <h2>Price Adjustment Details</h2>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2, display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "flex-start", justifyContent: "space-between", gap: 2 }}>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "50%" }}><b>Price Adjustment Month: </b>{projectDetails?.price_adjustment_month === "" ? "N/A" : projectDetails?.price_adjustment_month ?? "N/A"}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2, display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "flex-start", justifyContent: "space-between", gap: 2 }}>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "33%" }}><b>Labour Variance: </b>{projectDetails?.labour_variance ? projectDetails?.labour_variance : "N/A"}</FormLabel>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "33%" }}><b>Labour Constant: </b>{projectDetails?.labour_constant ? projectDetails?.labour_constant : "N/A"}</FormLabel>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "33%" }}><b>Labour Index (Lo): </b>{projectDetails?.labour_index ? projectDetails?.labour_index : "N/A"}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2, display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "flex-start", justifyContent: "space-between", gap: 2 }}>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "33%" }}><b>Material Variance: </b>{projectDetails?.material_variance ? projectDetails?.material_variance : "N/A"}</FormLabel>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "33%" }}><b>Material Constant: </b>{projectDetails?.material_constant ? projectDetails?.material_constant : "N/A"}</FormLabel>
                  <FormLabel sx={{ fontSize: { xs: 14, md: 18 }, width: "33%" }}><b>Material Index (Mo): </b>{projectDetails?.material_index ? projectDetails?.material_index : "N/A"}</FormLabel>
                </Grid>
              </Grid>
            </div>
          </div>
          </Card>
        }
{tableData && tableData?.length > 0 && 
        <Card sx={{mt: 3}}>
        <Grid container sx={{m: 4}}>
          <Grid item xs={12} align='CENTER' >
            <FormLabel sx={{ fontSize: 22 }} ><b>{"CONTRACT BOQ RATES"}</b></FormLabel>
          </Grid>
        </Grid>

            <div id="tableData">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead style={{ backgroundColor: '#87CEEB' }}>
                    <TableRow>
                      <TableCell>Sl No.</TableCell>
                      <TableCell>ITEM CODE</TableCell>
                      <TableCell colSpan={2}>ITEM DESCRIPTION</TableCell>
                      <TableCell>QUANTITY</TableCell>
                      <TableCell>UNIT</TableCell>
                      <TableCell>RATE (Nu)</TableCell>
                      <TableCell>AMOUNT (Nu)</TableCell>
                    </TableRow>
                  </TableHead> 
                  <TableBody>
                        {tableData?.map((section) => (
                          <React.Fragment key={section.construcionType}>
                            <TableRow>
                              <TableCell colSpan={8}><b>{section.constructionType}</b></TableCell>
                            </TableRow>
                            {section?.data?.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell>{row.sl_no}</TableCell>
                                <TableCell>{row.bsr_ref_no}</TableCell>
                                <TableCell colSpan={2}>{row.item_description}</TableCell>
                                <TableCell>{formatNumberWithCommasAndDecimals(row?.quantity)}</TableCell>
                                <TableCell>{row.unit}</TableCell>
                                <TableCell align="right">{formatNumberWithCommasAndDecimals(row?.rate)}</TableCell>
                                <TableCell align="right">{formatNumberWithCommasAndDecimals(row?.amount)}</TableCell>
                              </TableRow>
                            ))}
                          </React.Fragment>
                        ))}

                        <TableRow>
                          <TableCell colSpan={7} align="right" style={{ fontWeight: 600 }}><b>{"Sub-Total amount (Nu)"}</b></TableCell>
                          <TableCell colSpan={1} align="right" style={{ fontWeight: 600 }}><b>{formatNumberWithCommasAndDecimals(totalAmount)}</b></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={7} align="right"><b>Work Charge Amount (Nu):</b></TableCell>
                          <TableCell align="right">{formatNumberWithCommasAndDecimals(parseFloat(projectDetails?.workcharge_amount)?.toFixed(2))}</TableCell>

                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={7} align="right"><b>{`${parseFloat(projectDetails?.cont_percentage ?? 0)?.toFixed(2)}% Contingency Amount (Nu):`}</b></TableCell>
                          <TableCell align="right">{formatNumberWithCommasAndDecimals(parseFloat(projectDetails?.cont_amount)?.toFixed(2))}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={7} align="right"><b>Sub-Total Amount(Nu):</b></TableCell>
                          <TableCell align="right"><b>{formatNumberWithCommasAndDecimals(parseFloat((projectDetails?.workcharge_amount ?? 0) + (projectDetails?.cont_amount ?? 0))?.toFixed(2))}</b></TableCell></TableRow>
                        <TableRow>
                          <TableCell colSpan={7} align="right" style={{ fontWeight: 600 }}><b>Total Amount (Nu)</b></TableCell>
                          <TableCell colSpan={1} align="right" style={{ fontWeight: 600 }}><b>{formatNumberWithCommasAndDecimals(parseFloat((parseFloat((projectDetails?.workcharge_amount ?? 0) + (projectDetails?.cont_amount ?? 0))) + parseFloat(totalAmount))?.toFixed(2))}</b></TableCell>
                        </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              </div>
              </Card>
        }
 
        
      </div>
      {loading && (
        <Loader
          show={loading}
        />
      )}
      {(isPrinting || isDownloading) && (
        <Loader
          show={isPrinting ? isPrinting : isDownloading}
          text="Processing...Please wait."
        />
      )}

    </>
  )
}