import { MatxLoading } from 'app/components'
import { ServiceUrl } from 'app/utils/Constants'
import { UserRole } from 'app/utils/ProjectStatus'
import { SnackBarProperty } from 'app/utils/SnackBarProperty'
import axios from 'axios.js'
import jwtDecode from 'jwt-decode'
import { useSnackbar } from 'notistack'
import { createContext, useEffect, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
    menu: null,
    available: false
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }

    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000
    return decodedToken.exp > currentTime
}

export const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}

export const setMenu = (menu) => {
    if (menu) {
        localStorage.setItem('menu', menu)
    } else {
        localStorage.removeItem('menu')
    }
}

export const setUser = (user) => {
    if (user) {
        localStorage.setItem('user', user)
    } else {
        localStorage.removeItem('user')
    }
}

export const setProfileType = (type, role_name, roleId) => {
    if (type) {
        localStorage.setItem('viewType', type)
        localStorage.setItem('role_name', role_name)
        localStorage.setItem('role_id', roleId)

    } else {
        const user = JSON.parse(localStorage.getItem('user'))
        const userRoles = user?.roles?.map((element) => element?.roleId)
        if (userRoles?.includes(UserRole.ADMIN)) {
            localStorage.setItem('role_name', 'ADMIN')
            localStorage.setItem('role_id', UserRole.ADMIN)
        } else if (userRoles?.includes(UserRole.HOPA)) {
            localStorage.setItem('role_name', 'HEAD OF PROCURING AGENCY')
            localStorage.setItem('role_id', UserRole.HOPA)
        } else {
            localStorage.setItem('role_name', 'GENERAL USER')
            localStorage.setItem('role_id', UserRole.GENERAL_USER)
        }
        localStorage.setItem('viewType', 'default')
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user, menu } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
                menu
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                user,
            }
        }

        case 'MENU': {
            const menuByRole = action.payload

            return {
                ...state,
                isAuthenticated: true,
                menu: menuByRole,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                menu: null,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    menuByRole: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
    resetPassword: () => Promise.resolve()
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const login = async (usernameOrEmail, password) => {
        const response = await axios.post(ServiceUrl.signin, {
            usernameOrEmail,
            password,
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })

        if (response.status < 300) {
            const { accessToken } = response.data
            const decodedToken = jwtDecode(accessToken)
            const user = decodedToken.userInfo
            dispatch({
                type: 'LOGIN',
                payload: {
                    user,
                },
            })
            if (user.otp === 0 || user.otp === null) {
                setSession(accessToken)
                setUser(JSON.stringify(user))
                setProfileType()
                const roleId = localStorage.getItem('role_id')
                console.log("role_id", roleId)
                await menuByRole(parseFloat(roleId));
                navigate('/');
            } else {
                navigate('/reset-password/step1')
            }
        } else {
            const key = enqueueSnackbar(response?.data?.errorMessage, { variant: 'error' });
            SnackBarProperty.error(key, closeSnackbar);

        }

    }

    const menuByRole = async (roleId) => {
        const accessToken = window.localStorage.getItem('accessToken')
        const menuUrl = ((roleId === UserRole.ADMIN) || (roleId === UserRole.HOPA)) ? "/api/menu/project/" : "/api/menu/default/"
        const response = await axios.post(`${menuUrl}${roleId}${ServiceUrl.getAllMenuAndSubMenuByRole}`, {
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        })

        if (response.status < 300) {
            const data = response.data
            setMenu(JSON.stringify(data.obj))
            dispatch({
                type: 'MENU',
                payload: data.obj
                ,
            })
        } else {
            const key = enqueueSnackbar(response?.data?.message, { variant: 'error' });
            SnackBarProperty.error(key, closeSnackbar);

        }

    }

    const register = async (email, username, password) => {
        const response = await axios.post('/api/auth/register', {
            email,
            username,
            password,
        })

        const { accessToken, user } = response.data

        setSession(accessToken)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const resetPassword = async (usernameOrEmail, password) => {
        const response = await axios.post(ServiceUrl.resetPassword, { usernameOrEmail, password }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            if (res?.status < 300) {
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        menu: null,
                        user: null,
                    },
                })
                navigate('/landing')
                const key = enqueueSnackbar("Your Password change is successful. Please login with new password.", { variant: 'success' });
                SnackBarProperty.success(key, closeSnackbar);
            } else {
                const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
                SnackBarProperty.error(key, closeSnackbar);
            }
        });

    }



    const logout = () => {
        setSession(null)
        setUser(null)
        setMenu(null)
        setProfileType(null)
        dispatch({ type: 'LOGOUT' })
    }

    useEffect(() => {
        ; (async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')
                const user = JSON.parse(window.localStorage.getItem('user'))
                const menu = JSON.parse(window.localStorage.getItem('menu'))


                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken)

                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user: user,
                            menu: menu
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            menu: null,
                            user: null,
                        },
                    })
                    if (window.location.pathname !== '/landing' && window.location.pathname !== '/') {
                        const key = enqueueSnackbar("You have been logged out from the application. Please login again", { variant: 'info' });
                        SnackBarProperty.info(key, closeSnackbar);
                    }
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        menu: null,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                menuByRole,
                logout,
                register,
                resetPassword
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
