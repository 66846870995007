import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CloudDownload, ErrorOutline, Print } from "@mui/icons-material";
import { Box, Button, Card, FormLabel, Grid, Icon, MenuItem, TextField, styled } from "@mui/material";
import { Loader } from "app/components";
import { getAllProjectLocationListForBill } from "app/redux/actions/RABillActions";
import { getAllRaBillNoForReport, getRunningAccountBillForReport } from "app/redux/actions/ReportsAction";
import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from 'app/utils/Constants';
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation, currencyFormat, formatNumberWithCommasAndDecimals, getTwoDigitDecimal } from "app/utils/utils";
import { SimpleCard } from "components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";


const styles = {
  tableCell: {
    textAlign: "center"
  },
  subHeader: {
    textAlign: "center",
    fontWeight: 700
  }

}
const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));




export const RABillReport = () => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [projectDetails, setProjectDetails] = useState()
  const [isDownloading, setIsDownloading] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const { projectLocationList } = useSelector((state) => state.raBill);
  const [raBillList, setRaBillList] = useState([])

  const [tableData, setTableData] = useState()
  const [grandTotal, setGrandTotal] = useState()

  const [state, setState] = useState({
    project_location_id: '',
    ra_bill_id: ""
  })


  useEffect(() => {
    dispatch(getAllProjectLocationListForBill(showLoader, hideLoader, showToast))
  }, []);
  const showLoader = () => {
    setLoading(true)
  }
  const showToast = (msg) => {
    const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  }

  const hideLoader = () => {
    setLoading(false)
  }
  const handleChange = (event, source) => {
    setTableData()

    if (event.target.name === "project_location_id") {
      setRaBillList([])
      const filteredArray = projectLocationList.filter(project => {
        return project.project_location_id === event.target.value
      });
      state.project_name = filteredArray[0].project_name
      state.project_location_id = filteredArray[0].project_location_id
      state.project_id = filteredArray[0].project_id
      setLoading(true)
      setState({ ...state, [event.target.name]: event.target.value, ra_bill_id: "" });
      getAllRaBillNoForReport({ project_location_id: filteredArray[0].project_location_id }).then(res => {
        setLoading(false)
        if (res?.status < 300) {
          if (res?.data?.obj?.rabill_details?.length > 0) {
            setRaBillList(res?.data?.obj?.rabill_details)
          }

        } else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(error => {
        setLoading(false)
        const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })
    } else {
      setState({ ...state, [event.target.name]: parseInt(event.target.value) });
    }
  }


  const handleFormSubmit = async () => {
    if (state?.project_location_id === "") {
      const key = enqueueSnackbar("Please select project location", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    }
    else if (state?.ra_bill_id === "") {
      const key = enqueueSnackbar("Please select RA Bill No.", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    }
    else {
      setLoading(true)
      setTableData()

      getRunningAccountBillForReport({ project_location_id: state?.project_location_id, ra_bill_id: state?.ra_bill_id })
        .then((res) => {
          setLoading(false)
          console.log("response:", res)
          if (res?.status < 300) {
            if(res?.data?.success){
            if (res?.data?.obj) {
              if (res?.data?.obj && res?.data?.obj?.item) {
                if (res?.data?.obj?.item?.item && Object.keys(res?.data?.obj?.item?.item).length > 0) {
                  const table = []
                  Object.keys(res?.data?.obj?.item?.item).forEach(key => {
                    let obj = {}
                    const constructionName = key
                    const itemList = res?.data?.obj?.item?.item[key];
                    if (res?.data?.obj?.item?.item[key]?.length > 0) {
                      const subTotal = res?.data?.obj?.item?.item[key]?.reduce((sum, item) => sum + parseFloat((item?.new_quantity ?? 0) * (item?.contract_rate ?? 0)), 0);
                      const subTotalRevised = res?.data?.obj?.item?.item[key]?.reduce((sum, item) => sum + parseFloat(item.revised_amount), 0);
                      obj = {
                        construction_name: constructionName,
                        item_list: itemList,
                        sub_total_tender: parseFloat(subTotal ? subTotal : 0).toFixed(2),
                        sub_total_revised: parseFloat(subTotalRevised ? subTotalRevised : 0).toFixed(2)
                      }
                    }
                    // console.log(`Key: ${key}, Value: ${value}`);
                    table.push(obj)
                  });
                  console.log("table: ", table)
                  const grand_total_tender = table.reduce((sum, item) => sum + parseFloat(item?.sub_total_tender), 0)
                  const grand_total_revised = table.reduce((sum, item) => sum + parseFloat(item?.sub_total_revised), 0)
                  const obj = {
                    grand_tender_amount: parseFloat(grand_total_tender ? grand_total_tender : 0).toFixed(2),
                    grand_revised_amount: parseFloat(grand_total_revised ? grand_total_revised : 0).toFixed(2)
                  }
                  setGrandTotal(obj)
                  setTableData(table)
                }


                setProjectDetails({
                  client: res?.data?.obj?.item?.agency_name,
                  company: res?.data?.obj?.item?.company,
                  contract_no: res?.data?.obj?.item?.tender_id,
                  project_id: res?.data?.obj?.item?.project_id,
                  project_name: res?.data?.obj?.item?.project_name,
                  dzongkhag_name: res?.data?.obj?.item?.dzongkhagName,
                  rabill_no: res?.data?.obj?.item?.rabill_no,
                  contract_amount: res?.data?.obj?.item?.initial_contract_amount,
                  header: res?.data?.obj?.header_logo,
                  footer: res?.data?.obj?.footer_logo

                })
              }
            }
          }

          else{
            const key = enqueueSnackbar(res?.data?.message, { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
          }
          }
          else {
            const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
            SnackBarProperty.error(key, closeSnackbar);

          }
        }).catch(error => {
          setLoading(false)
          const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        })

    }

  }
  useEffect(() => {
    console.log("table Data:", tableData)
  }, [tableData])

  const handleReportDownload = (isPrint) => {
    if (isPrint) {
      setIsPrinting(true)
      setIsDownloading(false)
    }
    else {
      setIsPrinting(false)
      setIsDownloading(true)
    }
    
    const contentHeight = 150;
    const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image

    html2canvas(headerImage, {
      useCORS: true, // Add this line if the image is from a different domain
      allowTaint: true, // Add this line if the image is from a different domain
    }).then((canvas) => {
      
      const imgData = canvas.toDataURL("image/png");
      const footer = `${ServiceUrl?.showImageUrl}${projectDetails?.footer}`
      // Calculate the width and height of the header image based on the PDF page width
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // A4 dimensions in mm
      const pdfWidth = 297;
      const pdfHeight = 210;
      const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: [pdfWidth, pdfHeight],
      });
      // Calculate aspect ratio
      const aspectRatio = imgWidth / imgHeight;

      // Calculate scaled dimensions to fit within A4 while maintaining aspect ratio
      let scaledWidth = pdfWidth;
      let scaledHeight = pdfWidth / aspectRatio;

      if (scaledHeight > pdfHeight) {
          scaledHeight = pdfHeight;
          scaledWidth = pdfHeight * aspectRatio;
      }
      // Insert the header image into the PDF
      const topBorderPadding = 10; // Adjust the padding value as needed
      doc.setDrawColor(255, 255, 255); // Black color for border
      doc.setLineWidth(topBorderPadding); // Border width (padding)

      // Draw the top border (padding)
      doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);

      // Insert the header image into the PDF
      doc.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);
      const textX = 15;
      const textY = scaledHeight + 10; 

      // Add the text content below the header image
      doc.setFont('helvetica', 'bold'); // Set font to bold
      doc.setFontSize(12); // Set font size
      doc.text("RUNNING ACCOUNT BILL", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("RUNNING ACCOUNT BILL") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY);
      
      const textWidth = doc.getStringUnitWidth("Contract Amount:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      
      doc.setFontSize(8); // Set font size
      doc.text("Project Name:", textX, textY + 10);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.project_name, textX + textWidth + 5, textY + 10);
      doc.setFont('helvetica', 'bold');
      doc.text("Client:", textX, textY + 15);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.client, textX + textWidth + 5, textY + 15);
      doc.setFont('helvetica', 'bold');
      doc.text("Project ID:", textX, textY + 20);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.project_id}`, textX + textWidth + 5, textY + 20);
      doc.setFont('helvetica', 'normal');

      //   doc.setFont('helvetica', 'bold');
      //   doc.text("End Date:",textX, textY + 15);
      //   const fourthTextWidth = doc.getStringUnitWidth("End Date:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      //   doc.setFont('helvetica', 'normal');
      //   doc.text(`${moment(contractDetails?.work_end_date).format("DD-MM-YYYY")}`, textX + fourthTextWidth + 5, textY+15);
      //   doc.setFont('helvetica', 'normal');
      doc.setFont('helvetica', 'bold');
      doc.text("RA Bill No.:", textX, textY + 25);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.rabill_no}`, textX + textWidth + 5, textY + 25);
      doc.setFont('helvetica', 'bold');
      doc.text("Contract Amount:", textX, textY + 30);
      doc.setFont('helvetica', 'normal');
      doc.text(`${formatNumberWithCommasAndDecimals(parseFloat(projectDetails?.contract_amount).toFixed(2))}`, textX + textWidth + 5, textY + 30);
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(5);

      const headerSectionHeight = scaledHeight+45; // Adjust the value as needed
      // Calculate the starting Y-coordinate for the table
        const tableStartY = headerSectionHeight + 5;

      doc.setFont('helvetica', 'normal');

      const body = []
      body.push([{ content: "", colSpan: 3 },
      { content: "Tender", styles: { fontStyle: "bold",fontSize:8 } },
      { content: "Till Last RA", styles: { fontStyle: "bold",fontSize:8 } },
      { content: "Current", styles: { fontStyle: "bold",fontSize:8 } },
      { content: "Deviated", styles: { fontStyle: "bold",fontSize:8 } },
      { content: "Cumulative", styles: { fontStyle: "bold",fontSize:8 } },
        "",
      { content: "Tender", styles: { fontStyle: "bold",fontSize:8 } },
      { content: "Revised", styles: { fontStyle: "bold",fontSize:8 } },
      { content: "Tender", styles: { fontStyle: "bold",fontSize:8 } },
      { content: "Revised", styles: { fontStyle: "bold",fontSize:8 } }])
      tableData?.forEach((elem) => {
        body.push([
          { content: elem?.construction_name, colSpan: 12, styles: { fontStyle: "bold",fontSize:8 } }
        ])
        elem?.item_list?.forEach((item) => {
          body.push([
            {content:item?.item_code,styles:{fontSize:8}},
            {content:item?.boq_name,styles:{fontSize:8}},
            {content:item?.description,styles:{fontSize:8}},
            {content:`${formatNumberWithCommasAndDecimals(parseFloat(item?.contract_quantity ? item?.contract_quantity : 0).toFixed(2))}`,styles:{fontSize:8}},
            {content:`${formatNumberWithCommasAndDecimals(parseFloat(item?.prev_quantity ? item?.prev_quantity : 0).toFixed(2))}`,styles:{fontSize:8}},
            {content:`${formatNumberWithCommasAndDecimals(parseFloat(item?.new_quantity ? item?.new_quantity : 0).toFixed(2))}`,styles:{fontSize:8}},
            {content:`${formatNumberWithCommasAndDecimals(parseFloat(item?.deviation_quantity ? item?.deviation_quantity : 0).toFixed(2))}`,styles:{fontSize:8}},
            {content:`${formatNumberWithCommasAndDecimals(parseFloat(parseFloat(item?.prev_quantity ? item?.prev_quantity : 0) + parseFloat(item?.new_quantity ? item?.new_quantity : 0) + parseFloat(item?.deviation_quantity ? item?.deviation_quantity : 0)).toFixed(2))}`,styles:{fontSize:8}},
            {content:item?.unit_name,styles:{fontSize:8}},
            { content: formatNumberWithCommasAndDecimals(parseFloat(item?.contract_rate ? item?.contract_rate : 0).toFixed(2)), styles: { halign: "right",fontSize:8 } },
            { content: formatNumberWithCommasAndDecimals(parseFloat(item?.revised_rate ? item?.revised_rate : 0).toFixed(2)), styles: { halign: "right",fontSize:8 } },
            { content: formatNumberWithCommasAndDecimals(parseFloat(item?.new_quantity ? (parseFloat(item?.new_quantity * item?.contract_rate)) : 0)?.toFixed(2)), styles: { halign: "right",fontSize:8 } },
            { content: formatNumberWithCommasAndDecimals(parseFloat(item?.revised_amount ? item?.revised_amount : 0).toFixed(2)), styles: { halign: "right",fontSize:8 } }
          ])
        })
        body.push([
          { content: "Sub-Total", colSpan: 11, styles: { fontStyle: "bold", halign: "right",fontSize:8 } }, { content: formatNumberWithCommasAndDecimals(elem?.sub_total_tender), colSpan: 1, styles: { fontStyle: "bold", halign: "right",fontSize:8 } }, { content: elem?.sub_total_revised, colSpan: 1, styles: { fontStyle: "bold", halign: "right",fontSize:8 } }
        ])
      })
      body.push([
        { content: "Grand Total", colSpan: 11, styles: { fontStyle: "bold", halign: "right",fontSize:8 } },
        //{currencyFormat(getTwoDigitDecimal(grandTotal?.grand_tender_amount) + getTwoDigitDecimal(grandTotal?.grand_revised_amount))}
        { content: currencyFormat(getTwoDigitDecimal(grandTotal?.grand_tender_amount) + getTwoDigitDecimal(grandTotal?.grand_revised_amount)), colSpan: 1, styles: { fontStyle: "bold", halign: "right",fontSize:8 } },

        //  { content: formatNumberWithCommasAndDecimals(grandTotal?.grand_tender_amount), colSpan: 1, styles: { fontStyle: "bold", halign: "right",fontSize:8 } },
        // { content: formatNumberWithCommasAndDecimals(grandTotal?.grand_revised_amount), colSpan: 1, styles: { fontStyle: "bold", halign: "right",fontSize:8 } }
      ])


      doc.autoTable({
        startY: tableStartY,
        head: [[
          { content: "ITEM CODE", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2,fontSize:8 } },
          { content: "NAME", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2,fontSize:8 } },
          { content: "DESCRIPTION", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2,fontSize:8 } },
          { content: "QUANTITY", colSpan: 5, styles: { valign: "middle", halign: "center", cellPadding: 2,fontSize:8 } },
          { content: "UNIT", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2,fontSize:8 } },
          { content: "RATE (Nu)", colSpan: 2, styles: { valign: "middle", halign: "center", cellPadding: 2,fontSize:8 } },
          { content: "AMOUNT (Nu)", colSpan: 2, styles: { valign: "middle", halign: "center", cellPadding: 2,fontSize:8 } }
        ]],
        body: body, // Use the transformed data here
        theme: "grid",
        styles: {
          fillColor: [255, 255, 255], // Remove background color from the table
          lineColor: [0, 0, 0], // Set border color for main table
          lineWidth: 0.1, // Set border width for main table
          textColor: [0, 0, 0], // Set text color to black
          cellPadding: 2, // Add padding to cells
          fontSize: 7
        },
        columnStyles: {
          // fontSize:5

          // 8:{columnWidth:15},
          // 9:{columnWidth:15},
          // 10:{columnWidth:15},
          // 11:{columnWidth:15}
        },
      });
      doc.setFontSize(8);
      doc.setFont('helvetica', 'bold');// Set font size
      doc.text(`Checked by:  `, doc.internal.pageSize.getWidth() - 100, doc.autoTable.previous.finalY + 10);
      doc.text("Prepared by:", 15, doc.autoTable.previous.finalY + 10);

      // Function to add footer on each page
      function addFooter(pageNumber) {
        // const footerText = "Hello CMS User";
        // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
        const footerX = 0; // Starting X position for the footer
        const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = 10;
        // const availableWidth = pdfWidth - 2 * footerX;

        // doc.setTextColor(0, 0, 0); // Set text color to black
        // doc.setFontSize(10); // Set font size for the footer

        // // Calculate the width of the text and page numbers
        // const textWidth = doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        // const pageWidth = doc.getStringUnitWidth(pageString) * doc.internal.getFontSize() / doc.internal.scaleFactor;

        // // Calculate the space needed for equal spacing
        // const spaceWidth = (availableWidth - textWidth - pageWidth) / 2;

        // // Add footer text and page numbers in the same row, justified with space between them
        // doc.text(footerX, footerY, footerText);
        // doc.text(footerX + spaceWidth + textWidth, footerY, pageString);
        doc.addImage(footer, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
      }

      // Get the total number of pages
      const totalNumPages = doc.internal.getNumberOfPages();

      // Loop through each page to add the footer with page numbers
      for (let i = 1; i <= totalNumPages; i++) {
        doc.setPage(i);
        addFooter(i);
      }


      const scaleFactor = 1.0; // Adjust this value as needed
      const pdfBlob = doc.output('blob', { scale: scaleFactor });

      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open a new window with the PDF URL

      if (isPrint) {
        const newWindow = window.open(pdfUrl, '_blank');

        // After the new window is opened, you can close the URL object
        URL.revokeObjectURL(pdfUrl);
        if (newWindow) {
          newWindow.onload = () => {
            setIsPrinting(false)
            setIsDownloading(false)
            // Wait for the window to load and then trigger the print dialog
            newWindow.print();
          };
        }
      }

      setIsPrinting(false)
      setIsDownloading(false)

      isPrint === false && doc.save("Ra_Bill_Report.pdf");

    });

  };





  return (
    <>
      <div style={{ height: 'auto', width: "100%", padding: '30px', display: "flex", flexDirection: "column", gap: "20px" }}>

        <Grid container>

          <Grid item xs={12}>
            <Button
              size="small"
              sx={{ width: "100px" }}
              variant="contained"
              color="error"
              onClick={() => { window.history.back() }}
            >
              <Icon color="primary" fontSize="small">
                skip_previous
              </Icon>
              Back
            </Button>
          </Grid>
        </Grid>
        <SimpleCard>
          <ValidatorForm onSubmit={handleFormSubmit}>
            <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
              <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>Running Account Bill</b></Grid>
            </Grid>
            <Grid container spacing={3} >

              <Grid item sm={4} xs={12}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Project ID"
                  id="project_location_id"
                  variant="outlined"
                  name="project_location_id"
                  value={state?.project_location_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Project ID is required"]}
                >
                  {projectLocationList?.map((item) => (
                    <MenuItem value={item?.project_location_id} key={item?.project_id}>
                      {item?.project_id}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="RA Bill No."
                  id="rabill_is"
                  variant="outlined"
                  name="ra_bill_id"
                  value={state?.ra_bill_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Project ID is required"]}
                >
                  {raBillList?.map((item) => (
                    <MenuItem value={item?.runningaccountbill_id} key={item?.runningaccountbill_id}>
                      {item?.rabill_no}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item sm={4} xs={12}>


                <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>

              </Grid>

            </Grid>

          </ValidatorForm>
        </SimpleCard>
        {
          tableData && tableData?.length > 0 &&
          <Grid container>
            <Grid xs={12} md={6}>
              {checkValidation(projectDetails?.header) === false && <FormLabel sx={{ color: "#ff9800" }} color="warning">! Please upload header and footer for download/print</FormLabel>}
            </Grid>
            <Grid item xs={12} md={6}>
              <Box component="span" display="flex" justifyContent="flex-end">
                <Button
                  size="small"
                  sx={{ width: "130px", marginLeft: 4 }}
                  variant="contained"
                  color="warning"
                  disabled={projectDetails?.header ? false : true}
                  startIcon={<CloudDownload />}
                  onClick={() => { handleReportDownload(false) }}
                >
                  Download
                </Button>
                <Button
                  size="small"
                  sx={{ width: "100px", marginLeft: 2 }}
                  variant="contained"
                  color="primary"
                  disabled={projectDetails?.header ? false : true}
                  startIcon={<Print />}
                  onClick={() => { handleReportDownload(true) }}
                >
                  Print
                </Button>
              </Box>
            </Grid>

          </Grid>}

        {/* table */}
        {
          tableData && tableData?.length > 0 && projectDetails &&
          <Card sx={{ padding: 3 }}>

            <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
              <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
                  <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${projectDetails?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />

                </div>
              </div>

              <Grid container >
                <Grid item xs={12} sx={{ marginTop: 2, textAlign: "center" }}>
                  <FormLabel sx={{ fontSize: 20 }}><b>RUNNING ACCOUNT BILL</b></FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{projectDetails?.project_name}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Client: </b>{projectDetails?.client}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project ID: </b>{projectDetails?.project_id}</FormLabel>
                </Grid>
                {/* <Grid item  xs={12} sx={{marginTop:2}}>
                <FormLabel sx={{fontSize:18}}><b>Start Date.: </b>{moment(contractDetails?.work_start_date).format("DD-MM-YYY")}</FormLabel>
                </Grid>
                <Grid item  xs={12} sx={{marginTop:2}}>
                    <FormLabel sx={{fontSize:18}}><b>End Date.: </b>{moment(contractDetails?.work_end_date).format("DD-MM-YYY")}</FormLabel>
                </Grid> */}

                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Contract Amount: </b>{formatNumberWithCommasAndDecimals(parseFloat(projectDetails?.contract_amount ?? 0).toFixed(2))}</FormLabel>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: 2, marginBottom: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>RA Bill No.: </b>{projectDetails?.rabill_no}</FormLabel>
                </Grid>



              </Grid>
            </div>

            <div id="tableData">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead style={{ backgroundColor: '#87CEEB' }}>
                    <TableRow>
                      <TableCell rowSpan={2} style={{ width: '8%', textAlign: 'center' }}>Code</TableCell>
                      <TableCell rowSpan={2} style={{ width: '8%', textAlign: 'center' }}>Name</TableCell>
                      <TableCell rowSpan={2} style={{ width: '15%', whiteSpace: 'wrap', textAlign: "center" }} align="center">Description</TableCell>
                      <TableCell colSpan={5} align="center" style={{ width: '300px', whiteSpace: 'nowrap' }}>Quantity</TableCell>
                      <TableCell rowSpan={2} align="center">Unit</TableCell>
                      <TableCell colSpan={2} align="center">Rate(Nu)</TableCell>
                      <TableCell colSpan={2} align="center">Amount(Nu)</TableCell>

                    </TableRow>

                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={styles?.subHeader} colSpan={3}></TableCell>
                      <TableCell style={styles?.subHeader}>Tender</TableCell>
                      <TableCell style={styles?.subHeader}>Till Last RA</TableCell>
                      <TableCell style={styles?.subHeader}>Current</TableCell>
                      <TableCell style={styles?.subHeader}>Deviated</TableCell>
                      <TableCell style={styles?.subHeader}>Cumulative</TableCell>
                      <TableCell style={styles?.subHeader} colSpan={1}></TableCell>
                      <TableCell style={styles?.subHeader}>Tender</TableCell>
                      <TableCell style={styles?.subHeader}>Revised</TableCell>
                      <TableCell style={styles?.subHeader}>Tender</TableCell>
                      <TableCell style={styles?.subHeader}>Revised</TableCell>



                    </TableRow>
                    {tableData && tableData?.map((data) => {
                      return (
                        <>
                          <TableRow>
                            <TableCell colSpan={13}><b>{data?.construction_name}</b></TableCell>
                          </TableRow>
                          {
                            data?.item_list?.map((elem) => {
                              return (
                                <TableRow>
                                  <TableCell>{elem?.item_code}</TableCell>
                                  <TableCell>{elem?.boq_name}</TableCell>
                                  <TableCell style={{ width: "20%" }}><div className="description-cell">{elem?.description}</div></TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{formatNumberWithCommasAndDecimals(parseFloat(elem?.contract_quantity ? elem?.contract_quantity : 0).toFixed(2))}</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{formatNumberWithCommasAndDecimals(parseFloat(elem?.prev_quantity ? elem?.prev_quantity : 0).toFixed(2))}</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{formatNumberWithCommasAndDecimals(parseFloat(elem?.new_quantity ? elem?.new_quantity : 0).toFixed(2))}</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{formatNumberWithCommasAndDecimals(parseFloat(elem?.deviation_quantity ? elem?.deviation_quantity : 0).toFixed(2))}</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{formatNumberWithCommasAndDecimals(parseFloat(parseFloat(elem?.prev_quantity ? elem?.prev_quantity : 0) + parseFloat(elem?.new_quantity ? elem?.new_quantity : 0) + parseFloat(elem?.deviation_quantity ? elem?.deviation_quantity : 0)).toFixed(2))}</TableCell>
                                  <TableCell style={{ textAlign: "center" }} >{elem?.unit_name}</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{elem?.contract_rate ? formatNumberWithCommasAndDecimals(parseFloat(elem?.contract_rate).toFixed(2)) : 0}</TableCell>
                                  <TableCell>{formatNumberWithCommasAndDecimals(parseFloat(elem?.revised_rate ? elem?.revised_rate : 0).toFixed(2))}</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{formatNumberWithCommasAndDecimals(parseFloat(elem?.new_quantity ? (parseFloat(elem?.new_quantity) * parseFloat(elem?.contract_rate)) : 0).toFixed(2))}</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{formatNumberWithCommasAndDecimals(parseFloat(elem?.revised_amount ? elem?.revised_amount : 0).toFixed(2))}</TableCell>
                                </TableRow>
                              )
                            })
                          }
                          <TableRow>
                            <TableCell colSpan={10} align="right"><b>Sub-Total</b></TableCell>
                            <TableCell align="right"><b>{formatNumberWithCommasAndDecimals(data?.sub_total_tender)}</b></TableCell>
                            <TableCell align="right"><b>{formatNumberWithCommasAndDecimals(data?.sub_total_revised)}</b></TableCell>
                          </TableRow>
                        </>
                      )
                    })}
                    <TableRow>
                      <TableCell colSpan={10} align="right"><b>Grand Total</b></TableCell>
                      <TableCell align="right"><b>{currencyFormat(getTwoDigitDecimal(grandTotal?.grand_tender_amount) + getTwoDigitDecimal(grandTotal?.grand_revised_amount))}</b></TableCell>
                      {/* <TableCell align="right"><b>{formatNumberWithCommasAndDecimals(parseFloat(grandTotal?.grand_tender_amount).toFixed(2))}</b></TableCell> */}
                      {/* <TableCell align="right"><b>{formatNumberWithCommasAndDecimals(parseFloat(grandTotal?.grand_revised_amount).toFixed(2))}</b></TableCell> */}
                    </TableRow>

                  </TableBody>


                </Table>

              </TableContainer>
            </div>
          </Card>}

   
      </div>
      {loading && (
        <Loader
          show={loading}
        />
      )}
      {(isPrinting || isDownloading) && (
        <Loader
          show={isPrinting ? isPrinting : isDownloading}
          text="Processing...Please wait."
        />
      )}

    </>
  )
}