import axios from 'axios';
import { ServiceUrl } from 'app/utils/Constants';
import { SHOW_LOADING } from './RoleActions';
import { AppConstants } from 'app/utils/AppConstants';
import { UserRole } from 'app/utils/ProjectStatus';
import { getCurrentRole } from 'app/utils/utils';

export const GET_PROJECT_LOCATION_LIST = 'GET_PROJECT_LOCATION_LIST';
export const GET_BSR_PROJECT_LIST = 'GET_BSR_PROJECT_LIST';
export const GET_FUNDING_SOURCE_LIST = 'GET_FUNDING_SOURCE_LIST';
export const GET_USERS_PROJECT = 'GET_USERS_PROJECT'
export const GET_ROLES_PROJECT = 'GET_ROLES_PROJECT'

export const UPDATE_PROJECT_LOCATION_BSR_TAB = 'UPDATE_PROJECT_LOCATION_BSR_TAB';
export const GET_CONSTRUCTION_NAME_LIST_BY_PARAMS = "GET_CONSTRUCTION_NAME_LIST_BY_PARAMS"

export const GET_CONSTRUCTION_LEVEL_STAGE_PERCENTAGE_LIST = "GET_CONSTRUCTION_LEVEL_STAGE_PERCENTAGE_LIST"
export const GET_PROJECT_LEVEL_STAGE_PERCENTAGE_LIST = "GET_PROJECT_LEVEL_STAGE_PERCENTAGE_LIST"
export const GET_WORK_PLAN_LIST = "GET_WORK_PLAN_LIST"
export const GET_PROJECT_WORK_PLAN_LIST = "GET_PROJECT_WORK_PLAN_LIST"
export const GET_DZONGKHAG_LIST = 'GET_DZONGKHAG_LIST';

export const GET_ASSIGNED_ROLE_LIST = 'GET_ASSIGNED_ROLE_LIST';
export const GET_PENDING_HOPA_LIST = 'GET_PENDING_HOPA_LIST';
export const GET_ONGOING_PROJECT_LIST = 'GET_ONGOING_PROJECT_LIST';
export const RESET_PENDING_HOPA_LIST = 'RESET_PENDING_HOPA_LIST';

export const GET_ADVANCE_PAYMENT_VERIFICATION_COUNT = 'GET_ADVANCE_PAYMENT_VERIFICATION_COUNT';
export const GET_ADVANCE_PAYMENT_APPROVAL_COUNT = 'GET_ADVANCE_PAYMENT_APPROVAL_COUNT';

export const GET_PROJECT_IMPLEMENTATION_LIST = "GET_PROJECT_IMPLEMENTATION_LIST"
export const GET_REPORT_PROJECT_LOCATION_LIST = "GET_REPORT_PROJECT_LOCATION_LIST"

export const getAllProjectLocationList = (showLoader, hideLoader, showToast, filter = false, roleId = "test") => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader && showLoader()
  axios.post(ServiceUrl.getAllProjectLocationList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader && hideLoader()
    if (res?.status < 300) {
      console.log('my filter', filter, roleId)
      var responseObj = res?.data
      if (filter && roleId !== 'test') {
        responseObj = responseObj?.filter(element => element?.created_role_id === roleId)
        console.log('my filter response', responseObj)
      }
      dispatch({
        type: GET_PROJECT_LOCATION_LIST,
        payload: responseObj,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader && hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};

export const getAllProjectImplementationType = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader && showLoader()
  axios.post(ServiceUrl.getAllProjectImplementationType, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader && hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_PROJECT_IMPLEMENTATION_LIST,
        payload: res.data.obj,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader && hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};

export const getAllProjectLocationListForManageBSR = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getAllProjectLocationListForManageBSR + `?role_id=${getCurrentRole()}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_BSR_PROJECT_LIST,
        payload: res?.data ?? [],
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};

//getAllProjectLocationListForManageBSR

export const getAllFundingSourceList = (showLoading, hideLoading, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoading && showLoading()
  axios.post(ServiceUrl.getAllFundingSourceList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoading && hideLoading()
    if (res?.status < 300) {
      dispatch({
        type: GET_FUNDING_SOURCE_LIST,
        payload: res?.data,
      });
    } else {
      showToast(res?.data?.message ?? res?.message);
    }
  }).catch(function (error) {
    hideLoading && hideLoading()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};


export const getAllBMLItemRateListByCriteriaForProjectLocation = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getAllBMLItemRateListByCriteriaForProjectLocation, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllBMLItemRateListByCriteriaForProjectLocationForManageBSRView = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/${params.projectlocation_id}/${params.bml_year}${ServiceUrl.getAllBMLItemRateListByCriteriaForProjectLocationForManageBSRView}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const copyAllBMLItemRateListByCriteriaForProjectLocation = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.copyAllBMLItemRateListByCriteriaForProjectLocation, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const copyBsrAndBuiltUpItems = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.copyBsrAndBuiltUpItems, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
//

export const getCodeForNewBuildUpItemForProjectLocationByChapterIdAndYear = (location_id, bsr_year, chapter_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/${location_id}/${bsr_year}/${chapter_id}${ServiceUrl.getCodeForNewBuildUpItemForProjectLocationByChapterIdAndYear}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const saveOrupdateProjectLocationBsrBmlitems = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrupdateProjectLocationBsrBmlitems, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const addNewProjectLocationBsrBmlitems = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.addNewProjectLocationBsrBmlitems, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteProjectLocationBsrBmlitem = (itemId) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/project/${itemId}${ServiceUrl.deleteProjectLocationBsrBmlitem}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const isProjectLocationNotPresentInConstructionName = (project_location_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/${project_location_id}${ServiceUrl.isProjectLocationNotPresentInConstructionName}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
}

export const deleteCopyBsrItem = (project_location_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/project/${project_location_id}${ServiceUrl.deleteCopyBsrItem}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const getAllBuildUpItemLMCDetailsListByLmcIdForProjectLocation = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project${ServiceUrl.getAllBuildUpItemLMCDetailsListByLmcIdForProjectLocation}`, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteProjectLocationBsrBuiltupItemsDetails = (builtupitems_details_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/project/${builtupitems_details_id}${ServiceUrl.deleteProjectLocationBsrBuiltupItemsDetails}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const updateProjectLocationBsrBuiltUpItems = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project${ServiceUrl.updateProjectLocationBsrBuiltUpItems}`, params, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteProjectLocation = (project_location_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/project/projectLocation/${project_location_id}${ServiceUrl.deleteProjectLocation}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteProjectUserMap = (projectusermap_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/project/projectLocation/${projectusermap_id}${ServiceUrl.deleteProjectUserMap}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const updateProjectUserMap = (projectusermap_id, is_active) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/projectLocation/${projectusermap_id}/${is_active}${ServiceUrl.updateProjectUserMap}`, {}, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
  })
};

export const getAllProjectUserMapListByProjectLocationId = (project_location_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/projectLocation/${project_location_id}${ServiceUrl.getAllProjectUserMapListByProjectLocationId}`, {}, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
  })
};

export const saveOrUpdateProjectUserMap = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateProjectUserMap, params, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
  })
};

export const getAllBuildUpItemLMCListForProjectLocationByCriteria = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getAllBuildUpItemLMCListForProjectLocationByCriteria, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAlreadySavedBuildUpItemLMCListForProjectLocationByCriteriaForView = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getAlreadySavedBuildUpItemLMCListForProjectLocationByCriteriaForView, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAlreadySavedBMLItemRateListByCriteriaForProjectLocationForView = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getAlreadySavedBMLItemRateListByCriteriaForProjectLocationForView, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getUsersForProject = (showLoading, hideLoading, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoading && showLoading()
  axios.post(ServiceUrl.getAllUserListByAgencyId, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoading && hideLoading()
    if (res?.status < 300) {
      dispatch({
        type: GET_USERS_PROJECT,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoading && hideLoading()
    showToast(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong);;
  })
};

export const getAllProjectRoleList = (showLoading, hideLoading, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoading !== false && showLoading()
  axios.post(ServiceUrl.getAllProjectRoleList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoading !== false && hideLoading()
    if (res?.status < 300) {
      const filteredArray = res?.data?.filter((e) => e.projectrole_id !== 9 || e.projectrole_id !== 10 || e.projectrole_id !== 20)
      dispatch({
        type: GET_ROLES_PROJECT,
        payload: filteredArray,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoading !== false && hideLoading()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};


export const saveUpdateAndDeleteProjectLocation = (projectLocation) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateProjectLocation, projectLocation, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
}


export const saveUpdateAndDeleteBuildUpItemForProjectLocation = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveUpdateAndDeleteBuildUpItemForProjectLocation, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getProjectLocationIdBasedOnTenderIdAndInvalidReferenceNumber = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project${ServiceUrl.getProjectLocationIdBasedOnTenderIdAndInvalidReferenceNumber}`, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const addNewProjectLocationBsrBuiltupItemsDetailsListForProjectLocation = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.addNewProjectLocationBsrBuiltupItemsDetailsListForProjectLocation, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const addNewBuildUpItemForProjectLocation = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.addNewBuildUpItemForProjectLocation, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllBuildUpItemistForProjectLocationRateAnalysisByCriteria = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getAllBuildUpItemistForProjectLocationRateAnalysisByCriteria, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdateProjectWWO = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateProjectWWO, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const getProjectLocationWWO = (projectlocation_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/projectLocation/${projectlocation_id}${ServiceUrl.getProjectLocationWWO}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const getIndividualBuildUpItemListForProjectLocationRateAnalysisById = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getIndividualBuildUpItemListForProjectLocationRateAnalysisById, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllConstructionNameListByParams = (constructiontype_id, projectlocation_id, showLoading, hideLoading, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  const roleId = getCurrentRole()
  showLoading()
  axios.post(`/api/project/construction/${constructiontype_id}/${projectlocation_id}/${roleId}${ServiceUrl.getAllConstructionNameList}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoading()
    if (res?.status < 300) {
      dispatch({
        type: GET_CONSTRUCTION_NAME_LIST_BY_PARAMS,
        payload: res?.data,
      });
    } else {
      showToast(res?.data?.message)
    }

  }).catch(err => {
    hideLoading()
    showToast(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong)
  });
};

export const getProjectUserIdList = (projectlocation_id, roleId) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/projectBOQ/${projectlocation_id}/${roleId}${ServiceUrl.getProjectUserIdList}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const getAllConstructionNameListByParameters = (constructiontype_id, projectlocation_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  const roleId = getCurrentRole()
  return axios.post(`/api/project/construction/${constructiontype_id}/${projectlocation_id}/${roleId}${ServiceUrl.getAllConstructionNameList}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllProjectLocationListForAll = () => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getAllProjectLocationListForAll, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};



export const copyCNBOQByParameters = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.copyCNBOQ, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const fetchCopyCNBOQList = (from_constructionnameboq_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/construction/${from_constructionnameboq_id}${ServiceUrl.fetchCopyCNBOQList}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllProjectLocationListForProjectBoQ = () => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  dispatch({ type: SHOW_LOADING, payload: true });
  axios.post(ServiceUrl.getAllProjectLocationListForProjectBOQ + `?role_id=${getCurrentRole()}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    if (res?.status < 300) {
      dispatch({
        type: GET_PROJECT_LOCATION_LIST,
        payload: res.data,
      });
    } else {
      alert(res?.data?.message)
    }

  });
};

export const getAllProjectLocationsForContractProject = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getAllProjectLocationForContractDetails + `?role_id=${getCurrentRole()}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_PROJECT_LOCATION_LIST,
        payload: res?.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};


export const getAllProjectLocationListForReports = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getAllProjectLocationListForReports, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_REPORT_PROJECT_LOCATION_LIST,
        payload: res?.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};


export const getConstructionNameLevelListForStagePercentage = (constructionname_id, constructionnameboq_id, handleSuccess, showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(`/api/project/stageInformation/${constructionname_id}/${constructionnameboq_id}${ServiceUrl.getConstructionNameLevelListForStagePercentage}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_CONSTRUCTION_LEVEL_STAGE_PERCENTAGE_LIST,
        payload: res.data,
      });
      handleSuccess()
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};


export const getProjectLevelListForStagePercentage = (project_location_id, handleSuccess, showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(`/api/project/stageInformation/${project_location_id}${ServiceUrl.getProjectLevelListForStagePercentage}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      var stageArray = []
      if (res?.data?.length > 0) {
        const groupedStages = Object.groupBy(res?.data, stage => {
          return stage.constructionname_id;
        });
        for (const property in groupedStages) {
          const groupedKey = Number(property)
          if (groupedStages[property].length > 1) {
            var filteredArray = res?.data?.filter((newElement) => {
              return newElement.constructionname_id === groupedKey
            })
            var newValue = ((filteredArray[0].cn_percentage) / (filteredArray.length))
            filteredArray.forEach((content, index) => {
              content.cn_percentage = newValue
              stageArray.push(content)
            })
          } else {
            var newFilteredArray = res?.data?.filter((element) => {
              return element.constructionname_id === groupedKey
            })
            stageArray.push(newFilteredArray[0])
          }
        }
      }
      dispatch({
        type: GET_PROJECT_LEVEL_STAGE_PERCENTAGE_LIST,
        payload: stageArray,
      });
      handleSuccess()
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};


export const getAllWorkPlanByConstructionlevelOrProjectLevel = (constructionaname_id, project_location_id, handleSuccess, showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(`/api/implementation/workPlan/${project_location_id}/${constructionaname_id}${ServiceUrl.getAllWorkPlanByConstructionlevelOrProjectLevel}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_WORK_PLAN_LIST,
        payload: res?.data?.obj,
      });
      handleSuccess()
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.message ?? error.message);
  })
};

export const getAllWorkPlanDetails = (type, data, boq_name, showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(`/api/implementation/workPlan${ServiceUrl.getAllWorkPlanDetails}`, type === "name_id" ? { constructionname_id: data } : { workplan_date: data?.date, constructionname_id: data?.cn_id }, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      res?.data.sort((a, b) => {
        // First, compare by stageinformation_id
        if (a.stageinformation_id < b.stageinformation_id) return -1;
        if (a.stageinformation_id > b.stageinformation_id) return 1;

        // If stageinformation_id is the same, compare by start_date
        if (a.start_date < b.start_date) return -1;
        if (a.start_date > b.start_date) return 1;

        // If both are equal, no change in order
        return 0;
      });
      const filteredArray = res?.data?.filter(planObj => {
        return planObj.boq_name === boq_name
      });
      dispatch({
        type: GET_WORK_PLAN_LIST,
        payload: filteredArray,
      });
      // handleSuccess()
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.message ?? error.message);
  })
};

export const getOngoingProjectDetailsForLandingPage = (handleSuccess, showLoader, hideLoader, showToast) => (dispatch) => {
  showLoader()
  axios.get(ServiceUrl.getOngoingProjectDetailsForLandingPage).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_ONGOING_PROJECT_LIST,
        payload: res?.data ?? [],
      });
      handleSuccess()
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.message ?? error.message);
  })
};


export const getAllWorkPlanByProjectLevel = (project_location_id, handleSuccess, showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(`/api/implementation/workPlan/${project_location_id}${ServiceUrl.getAllWorkPlanByProjectLevel}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_PROJECT_WORK_PLAN_LIST,
        payload: res?.data,
      });
      handleSuccess()
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.message ?? error.message);
  })
};


// TO POPULATE DZONGKHAG LIST
export const getAllDzongkhagList = (showLoading, hideLoading, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoading && showLoading()
  axios.post(ServiceUrl.getAllDzongkhagList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoading && hideLoading()
    if (res?.status < 300) {
      dispatch({
        type: GET_DZONGKHAG_LIST,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }

  }).catch(function (error) {
    hideLoading && hideLoading()
    showToast(error?.data?.message)
  })
};



export const getAllAssignedProjectList = (showLoading, hideLoading, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoading()
  axios.post(ServiceUrl.getAllAssignedProjectList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoading()
    if (res?.status < 300) {
      const newAssignedProjectList = []
      const projectList = res?.data
      if ((projectList?.length ?? 0) > 0) {
        projectList?.forEach((item, index) => {
          const alreadyExistsList = newAssignedProjectList?.filter((childItem) => {
            return childItem?.project_id === item?.project_id
          })
          if (alreadyExistsList?.length > 0) {
            projectList[index].alreadyExist = true
          } else {
            projectList[index].alreadyExist = false
          }
          newAssignedProjectList?.push(item)
        })
        dispatch({
          type: GET_ASSIGNED_ROLE_LIST,
          payload: newAssignedProjectList,
        });
      }
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoading()
    showToast(error?.response?.data?.message ?? error.message);
  })
};
export const getAdvancePaymentVerificationCount = () => {
  const accessToken = window.localStorage.getItem('accessToken')

  return axios.post(ServiceUrl.getAdvancePaymentVerificationCount, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getAdvancePaymentApprovalCount = () => {
  const accessToken = window.localStorage.getItem('accessToken')

  return axios.post(ServiceUrl.getAdvancePaymentApprovalCount, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllPendingHoPAUserList = (showLoading, hideLoading, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoading()
  // dispatch({
  //   type: RESET_PENDING_HOPA_LIST,
  //   payload: [],
  // });
  axios.post(ServiceUrl.getAllPendingHoPAUserList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoading()
    if (res?.status < 300) {
      dispatch({
        type: GET_PENDING_HOPA_LIST,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoading()
    showToast(error?.response?.data?.message ?? error.message);
  })
};
export const resetPendingHoPA = () => (dispatch) => {
  dispatch({
    type: RESET_PENDING_HOPA_LIST,
    payload: [],
  });
}

export const approveOrRejectHoPA = (userId, approveOrReject, showLoader, hideLoader, showToast, handleSuccess) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(`/api/auth/${userId}/${approveOrReject}${ServiceUrl.approveOrRejectHoPA}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300 && res?.data?.success === true) {
      // dispatch({ type: GET_PENDING_HOPA_LIST, payload: res?.data?.obj});
      const message = approveOrReject === "APPROVE" ? "HoPA user has been approved successfully" : "HoPA user has been rejected successfully"
      handleSuccess(message)
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};