
import axios from 'axios';
import { ServiceUrl } from 'app/utils/Constants';
import { checkValidation, eql, getCurrentRole, getTwoDigitDecimal } from 'app/utils/utils';
import { ProjectStatus } from 'app/utils/ProjectStatus';

export const RA_BILLS_LIST = 'RA_BILLS_LIST';
export const PRICE_ADJUSTMENT_LIST_BILL = 'PRICE_ADJUSTMENT_LIST_BILL';
export const GET_PROJECT_LOCATION_LIST_BILL = 'GET_PROJECT_LOCATION_LIST_BILL';
export const CREDIT_DEBIT_LIST = 'CREDIT_DEBIT_LIST'
export const RECLAIM_ADVANCES_LIST = 'RECLAIM_ADVANCES_LIST'
export const APPROVED_RA_BILLS_LIST = "APPROVED_RA_BILLS_LIST"
export const RECOVERED_RA_BILLS_LIST = "RECOVERED_RA_BILLS_LIST"
export const RETENTION_LIST = "RETENTION_LIST"
export const GET_CONSTRUCTION_TYPE_RABILL = "GET_CONSTRUCTION_TYPE_RABILL"
export const GET_ALL_CLASSIFICATIONS_RA_BILL = 'GET_ALL_CLASSIFICATIONS_RA_BILL'

export const getAllProjectLocationListForBill = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader && showLoader()
  axios.post(ServiceUrl.getAllProjectLocationForContractDetails + `?role_id=${getCurrentRole()}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader && hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_PROJECT_LOCATION_LIST_BILL,
        payload: res?.data ?? [],
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader && hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};


export const getApprovedRaBillForRecovery = (project_location_id, showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader && showLoader()
  axios.post(`/api/implementation/runningAccountBill/${project_location_id}${ServiceUrl.getApprovedRaBillForRecovery}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader && hideLoader()
    if (res?.status < 300) {
      dispatch({ type: APPROVED_RA_BILLS_LIST, payload: res?.data });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader && hideLoader()
    showToast(error?.response?.message ?? error?.message);
  })
};

export const getAllRaBillRecoveryList = (runningaccountbill_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/runningAccountBill/${runningaccountbill_id}${ServiceUrl.getAllRaBillRecoveryList}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getRetentionReleaseList = (status, showLoader, hideLoader, showToast, handleRetentionListSucces) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader && showLoader()
  axios.post(`/api/implementation/runningAccountBill/${status}/${getCurrentRole()}${ServiceUrl.getRetentionReleaseList}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader && hideLoader()
    if (res?.status < 300) {
      dispatch({ type: RETENTION_LIST, payload: res?.data ?? [] });
      handleRetentionListSucces && handleRetentionListSucces()
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader && hideLoader()
    showToast(error?.response?.message ?? error?.message);
  })
};



export const getAllRunningAccountBillByProjectLocation = (project_location_id, statusType, type, showLoader, hideLoader, showToast, handleRABillSucces) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader && showLoader()
  axios.post(`/api/implementation/runningAccountBill/${project_location_id}/${statusType}/${type}${ServiceUrl.getAllRunningAccountBillByProjectLocationId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader && hideLoader()
    if (res?.status < 300) {
      dispatch({ type: RA_BILLS_LIST, payload: res?.data });
      handleRABillSucces && handleRABillSucces()
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader && hideLoader()
    showToast(error?.response?.message ?? error?.message);
  })
};

export const getRunningAccountBillByProjectLocationId = (project_location_id, statusId, showLoader, hideLoader, showToast, handleRABillSucces, assignedUSerIdList = []) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader && showLoader()
  axios.post(`/api/implementation/runningAccountBill/${project_location_id}${ServiceUrl.getRunningAccountBillByProjectLocationId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader && hideLoader()
    if (res?.status < 300) {
      if (res?.data?.length > 0) {
        if (statusId === ProjectStatus.CREATED) {
          dispatch({ type: RA_BILLS_LIST, payload: res?.data });
        } else if (statusId === ProjectStatus.SUBMITTED) {
          var filteredArray = res?.data?.filter((element) => element.status >= statusId && element.status !== ProjectStatus.REJECTED)
          if (assignedUSerIdList?.length > 0) {
            filteredArray?.forEach((element, index) => {
              filteredArray[index] = modifiedRABill(element, assignedUSerIdList)
            })
          } else {
            filteredArray?.forEach((element, index) => {
              filteredArray[index].isEligibleForRecommendation = true
            })
          }
          dispatch({ type: RA_BILLS_LIST, payload: filteredArray });
        }
        else {
          const filteredArray = res?.data?.filter((element) => element.status >= statusId && element.status !== ProjectStatus.REJECTED && element.status !== ProjectStatus.SENT_FOR_REVIEW)
          dispatch({ type: RA_BILLS_LIST, payload: filteredArray });
        }
      } else {
        dispatch({ type: RA_BILLS_LIST, payload: res?.data });
      }
      handleRABillSucces && handleRABillSucces()
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader && hideLoader()
    showToast(error?.response?.message ?? error?.message);
  })
};

const modifiedRABill = (raBill, assignedUSerIdList) => {
  var raBillElement = raBill
  var isEligibleForRecommendation = false
  if (checkValidation(raBillElement?.completed_by)) {
    var completed_by = raBillElement?.completed_by?.split(",").filter(x => x.trim().length && !isNaN(x)).map(Number)
    completed_by?.sort()
    raBillElement.completed_by = completed_by
  } else {
    raBillElement.completed_by = []
  }

  if (eql(assignedUSerIdList, raBillElement?.completed_by)) {
    isEligibleForRecommendation = true
  }

  const loggedInUser = JSON.parse(window.localStorage.getItem('user'));
  raBillElement.isCompletedForSecondary = raBillElement?.completed_by?.includes(loggedInUser.id)
  raBillElement.isEligibleForRecommendation = isEligibleForRecommendation
  console.log('my element', raBillElement)
  return raBillElement
}

export const getAllClassificationsListForRaBill = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader && showLoader()
  axios.post(ServiceUrl.getAllClassificationList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    if (res?.status < 300) {
      var dataList = res?.data?.map((classification) => classification.classification_id)
      var joinedDatas = dataList?.length > 0 ? dataList?.join(',') : ''
      dispatch({
        type: GET_ALL_CLASSIFICATIONS_RA_BILL,
        payload: res?.data?.length > 0 ? [{ classification_name: "All", classification_id: joinedDatas }, ...res?.data] : res?.data,
      });
    }
  }).catch(function (error) {
    hideLoader && hideLoader()
    showToast(error?.response?.message ?? error?.message);
  })
};


export const getAllRecommededBillsByProjectLocation = (project_location_id, statusType, type) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/runningAccountBill/${project_location_id}/${statusType}/${type}${ServiceUrl.getAllRunningAccountBillByProjectLocationId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAmendmentRecoveryAmountByClassificationId = (runningaccountbill_id, classification_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/runningAccountBill/${runningaccountbill_id}/${classification_id}${ServiceUrl.getAmendmentRecoveryAmountByClassificationId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getRABillWorkDoneByBillId = (bill_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`api/implementation/runningAccountBill/${bill_id}/getWorkDoneAmountForPriceAdjustment`, {}, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
}


export const saveOrUpdateRunningAccountBill = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateRunningAccountBill, params, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const saveOrUpdateRetentionRelease = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateRetentionRelease, params, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const sendRaBillReportEmail = ({ ra_bill_id, data }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`api/implementation/runningAccountBill/${ra_bill_id}/saveOrUpdateRunningAccountBill`, data, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const getRetentionAmountByProjectLocationId = (project_location_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`api/implementation/runningAccountBill/${project_location_id}${ServiceUrl.getRetentionAmountByProjectLocationId}`, {}, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
  })
}

export const saveOrUpdateRaBillRecovery = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateRaBillRecovery, params, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
}


export const saveOrUpdateRaBillAmendment = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateRaBillAmendment, params, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
}

export const updateBalanceQuantity = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.updateBalanceQuantity, params, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
}

export const deleteRunningAccountBillDetailsDOM = (runningaccountbill_details_dom_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/implementation/runningAccountBill/${runningaccountbill_details_dom_id}${ServiceUrl.deleteRunningAccountBillDetailsDOM}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteRunningAccountBillId = (runningaccountbill_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/implementation/runningAccountBill/${runningaccountbill_id}${ServiceUrl.deleteRunningAccountBillId}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteRaBillAmendmentDetailsDOM = (rabillamendment_details_dom_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/implementation/runningAccountBill/${rabillamendment_details_dom_id}${ServiceUrl.deleteRaBillAmendmentDetailsDOM}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteRaBillAmendmentDetails = (rabillamendment_details_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/implementation/runningAccountBill/${rabillamendment_details_id}${ServiceUrl.deleteRaBillAmendmentDetails}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteRaBillRecoveryDetails = (rabillrecovery_details_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/implementation/runningAccountBill/${rabillrecovery_details_id}${ServiceUrl.deleteRaBillRecoveryDetails}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteRaBillRecoveryDetailsDom = (rabillrecovery_details_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/implementation/runningAccountBill/${rabillrecovery_details_id}${ServiceUrl.deleteRaBillRecoveryDetailsDom}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveRunningAccountBillDetailsDOM = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveRunningAccountBillDetailsDOM, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdateRaBillRecoveryDetailsDoM = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateRaBillRecoveryDetailsDoM, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getRABillDoMList = (runningaccountbill_details_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/runningAccountBill/${runningaccountbill_details_id}${ServiceUrl.getRABillDoMList}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveRaBillAmendmentDetailsDOM = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveRaBillAmendmentDetailsDOM, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllRaBillAmendmentByRunningAccountBillId = (runningaccountbill_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/runningAccountBill/${runningaccountbill_id}${ServiceUrl.getAllRaBillAmendmentByRunningAccountBillId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const checkItemPresentInRunningAccountBill = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.checkItemPresentInRunningAccountBill, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


//, showLoader, hideLoader, showToast
export const getAllPriceAdjustmentReport = (runningaccountbill_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  // showLoader()
  return axios.post(`/api/implementation/report/${runningaccountbill_id}${ServiceUrl.getAllPriceAdjustmentReport}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
  // .then((res) => {
  //   hideLoader()
  //   if (res?.status < 300) {
  // dispatch({ type: PRICE_ADJUSTMENT_LIST, payload: res?.data?.obj?.price_adjustment });
  //   } else {
  //     showToast(res?.data?.message)
  //   }
  // }).catch(function (error) {
  //   hideLoader()
  //   showToast(error?.response?.message ?? error?.message);
  // })
};
//, showLoader, hideLoader, showToast
export const getAllAdvanceDetailsReport = (project_location_id, runningaccountbill_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  // showLoader()
  return axios.post(`/api/implementation/report/${project_location_id}/${runningaccountbill_id}${ServiceUrl.getAllAdvanceDetailsReport}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
  // .then((res) => {
  //     hideLoader()
  //     if (res?.status < 300) {
  //       dispatch({ type: RECLAIM_ADVANCES_LIST, payload: res?.data?.obj?.advance_details });
  //     } else {
  //       showToast(res?.data?.message)
  //     }
  //   }).catch(function (error) {
  //     hideLoader()
  //     showToast(error?.response?.message ?? error?.message);
  //   })
};
//, showLoader, hideLoader, showToast
export const getDebitCreditDetailsForRABillReport = (project_location_id, ra_bill_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  // showLoader()
  return axios.post(`/api/implementation/report/${project_location_id}/${ra_bill_id}${ServiceUrl.getDebitCreditDetailsForRABillReport}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
  // .then((res) => {
  //   hideLoader()
  //   if (res?.status < 300) {
  //     dispatch({ type: CREDIT_DEBIT_LIST, payload: res?.data?.obj?.debit_credit_details });
  //   } else {
  //     showToast(res?.data?.message)
  //   }
  // }).catch(function (error) {
  //   hideLoader()
  //   showToast(error?.response?.message ?? error?.message);
  // })
};