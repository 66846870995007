import { APPROVED_RA_BILLS_LIST, CREDIT_DEBIT_LIST, GET_ALL_CLASSIFICATIONS_RA_BILL, GET_CONSTRUCTION_TYPE_RABILL, GET_PROJECT_LOCATION_LIST_BILL, PRICE_ADJUSTMENT_LIST_BILL, RA_BILLS_LIST, RECLAIM_ADVANCES_LIST, RECOVERED_RA_BILLS_LIST, RETENTION_LIST } from "../actions/RABillActions";


const initialState = {
  projectLocationList: [],
  raBillList: [],
  approvedRaBillList: [],
  recoveredRaBillList: [],
  constructionTypeList: [],
  priceAdjustmentList: [],
  creditDebitList: [],
  reclaimAdvancesList: [],
  retentionList: [],
  classificationList: []
}

const RABillReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECOVERED_RA_BILLS_LIST: {
      return {
        ...state,
        recoveredRaBillList: { ...action.payload },
      };
    }
    case GET_ALL_CLASSIFICATIONS_RA_BILL: {
      return {
        ...state,
        classificationList: [...action.payload],
      };
    }
    case RETENTION_LIST: {
      return {
        ...state,
        retentionList: [...action.payload],
      };
    }
    case APPROVED_RA_BILLS_LIST: {
      return {
        ...state,
        approvedRaBillList: [...action.payload],
      };
    }
    case RECLAIM_ADVANCES_LIST: {
      return {
        ...state,
        reclaimAdvancesList: [...action.payload],
      };
    }
    case CREDIT_DEBIT_LIST: {
      return {
        ...state,
        creditDebitList: [...action.payload],
      };
    }
    case PRICE_ADJUSTMENT_LIST_BILL: {
      return {
        ...state,
        priceAdjustmentList: [...action.payload],
      };
    }
    case GET_CONSTRUCTION_TYPE_RABILL: {
      return {
        ...state,
        constructionTypeList: [...action.payload],
      };
    }
    case RA_BILLS_LIST: {
      return {
        ...state,
        raBillList: [...action.payload],
      };
    }

    case GET_PROJECT_LOCATION_LIST_BILL: {
      return {
        ...state,
        projectLocationList: [...action.payload],
      };
    }

    default: {
      return { ...state }
    }
  }
};

export default RABillReducer;
