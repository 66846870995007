import { CloudDownload, Print } from "@mui/icons-material";
import { Box, Button, Card, FormLabel, Grid, Icon, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, styled } from "@mui/material";
import { Loader } from "app/components";
import { getBMLItemsForReport, getWorkerAttendanceReport } from "app/redux/actions/ReportsAction";
import { getAllProjectLocationByAgency, getAllResourceRequisitionByProjectLocation } from "app/redux/actions/ResourceForeCastActions";
import { AppConstants } from "app/utils/AppConstants";
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { SimpleCard } from "components";
import jsPDF from "jspdf";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const styles = {
  tableCell: {
    textAlign: "center"
  },
  subHeader: {
    textAlign: "center",
    fontWeight: 700
  }

}
const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

export const WorkerAttendanceReport = () => {
  const dispatch = useDispatch();
  const [isDownloading, setIsDownloading] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [requisitionList, setRequisitionList] = useState()
  const [bmlItemsList, setBmlItemsList] = useState()
  const { projectLocationListForAgency, resourceForecastList } = useSelector((state) => state?.resourceForecast)
  const [tableData, setTableData] = useState([])
  const months =
    [
      {
        value: 1,
        month: "January",
        max: 31
      },
      {
        value: 2,
        month: "February",
        max: 30
      },
      {
        value: 3,
        month: "March",
        max: 31
      },
      {
        value: 4,
        month: "April",
        max: 30
      },
      {
        value: 5,
        month: "May",
        max: 31
      },
      {
        value: 6,
        month: "June",
        max: 30
      },
      {
        value: 7,
        month: "July",
        max: 31
      },
      {
        value: 8,
        month: "August",
        max: 31
      },
      {
        value: 9,
        month: "September",
        max: 30
      },
      {
        value: 10,
        month: "October",
        max: 31
      },
      {
        value: 11,
        month: "November",
        max: 30
      },
      {
        value: 12,
        month: "December",
        max: 31
      }]

  const [state, setState] = useState({
    project_location_id: '',
  })


  useEffect(() => {
    dispatch(getAllProjectLocationByAgency(showLoader, hideLoader, showToast))
  }, []);

  const showLoader = () => {
    setLoading(true)
  }
  const showToast = (message, type) => {
    const key = enqueueSnackbar(message ? message : AppConstants.somethingWentWrong, { variant: type === "info" ? "info" : 'error' });
    type === "info" ? SnackBarProperty.info(key, closeSnackbar) : SnackBarProperty.error(key, closeSnackbar)

  }

  const hideLoader = () => {
    setLoading(false)
  }

  const handleChange = (event, source) => {

    if (event.target.name === "project_location_id") {
      setTableData([])
      const filteredArray = projectLocationListForAgency.filter(project => {
        return project.project_location_id === event.target.value
      });


      setState({
        [event.target.name]: event.target.value,
        project_name: filteredArray[0].project_name,
        project_location_id: filteredArray[0].project_location_id,
        project_id: filteredArray[0].project_id,
        project_location: filteredArray[0].project_location

      });
      setLoading(true)
      getAllResourceRequisitionByProjectLocation({ project_location_id: event.target.value }).then(res => {
        setLoading(false)

        if (res?.status < 300) {
          if (res?.data?.obj?.length > 0) {
            //setRequisitionList(res?.data?.obj)
            // Filter the data based on resourceforecast_category_id equal to 3
            const filteredData = res.data.obj.filter(item => item.resourceforecast_category_id === 1);

            // Set the filtered data to the requisitionList state
            setRequisitionList(filteredData);
          }
          else {
            const key = enqueueSnackbar("No requisition found", { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
          }


        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(err => {
        setLoading(false)
        const key = enqueueSnackbar(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })

    }
    else if (event.target.name === "requisition_id") {
      setTableData([])
      const filteredArray = requisitionList.filter(requisition => {
        return requisition.resourcerequisition_id === event.target.value
      });
      setState({
        ...state, [event.target.name]: event.target.value,
        req_month: months[filteredArray[0].req_month - 1],
        req_no: filteredArray[0].requisition_no,
        req_date: moment(filteredArray[0].requisition_date).format("DD-MM-YYYY"),
        req_year: filteredArray[0].req_year,
        category: filteredArray[0].resourceforecast_category_name
      });
      setLoading(true)
      getBMLItemsForReport(event.target.value).then(res => {
        setLoading(false)
        if (res?.status < 300) {
          if (res?.data?.success) {
            if (res?.data?.obj?.item?.length > 0) {
              setBmlItemsList(res?.data?.obj?.item)
            }
            else {
              const key = enqueueSnackbar("No BML items found", { variant: 'info' });
              SnackBarProperty.info(key, closeSnackbar);
            }
          }
          else {
            const key = enqueueSnackbar(res?.data?.message, { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
          }
        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(err => {
        setLoading(false)
        const key = enqueueSnackbar(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })
    }
    else {
      setTableData([])
      setState({ ...state, [event.target.name]: event.target.value })
    }
  }


  const handleFormSubmit = () => {
    setTableData([])
    if (state.year === undefined || state.year === null) {
      const key = enqueueSnackbar("Please enter year", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    }
    else if (state?.year && state?.year?.toString()?.length === 4) {
      setLoading(true)
      const params = {
        project_location_id: state?.project_location_id,
        resourcerequisition_id: state?.requisition_id,
        month: state?.month,
        year: parseInt(state?.year),
        resourcerequisition_details_id: 0 //state?.resourcerequisition_details_id

      }
      getWorkerAttendanceReport(params).then(res => {
        setLoading(false)
        if (res?.status < 300 && res?.data?.success) {
          if (res?.data?.obj?.item?.worker_attendance_report && Object.keys(res?.data?.obj?.item?.worker_attendance_report)?.length > 0) {
            const transformedTableData = Object.keys(res?.data?.obj?.item?.worker_attendance_report).map(workerName => {
              const checkedMonth = months?.filter(elem => state?.month === elem?.value)
              console.log("checked month:", checkedMonth)
              const attendance = []
              let increaseFeb = false
              // if(((parseInt(state?.year) % 4 === 0 && parseInt(state?.year) % 100 !== 0) || (parseInt(state?.year) % 400 === 0))&&state?.month===2){

              //   console.log("error occured")
              //   increaseFeb=true
              // }
              for (let i = 1; i <= checkedMonth[0]?.max; i++) {
                let matched = "na"
                res?.data?.obj?.item?.worker_attendance_report[workerName]?.forEach(elem => {
                  if (parseInt(moment(elem?.attendance_date).date()) === i) {
                    if (elem?.is_present === true) {
                      // attendance.push({text:"P",count:1})
                      matched = "pr"
                    }
                    else if (elem?.is_present === false) {
                      // attendance.push({text:"P",count:1})
                      matched = "ab"
                    }
                    else {
                      matched = "na"
                    }
                  }
                })
                if (matched === "pr") {
                  attendance.push({ text: "P", count: 1 })
                }
                else if (matched === "ab") {
                  attendance.push({ text: "A", count: 1 })
                }
                else {
                  attendance.push({ text: "-", count: 1 })
                }

              }
              return {
                name: workerName,
                details: attendance,
                category: res?.data?.obj?.item?.worker_attendance_report?.[workerName]?.[0]?.category
              }
            });
            setTableData(transformedTableData)
          }
          else {
            const key = enqueueSnackbar("No worker attendance details found", { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
          }

        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(err => {
        console.log("error1:", err)
        setLoading(false)
        const key = enqueueSnackbar(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })
    }
    else {
      const key = enqueueSnackbar("Please enter valid year", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    }

  }


  const getTotalPresent = (index) => {
    const sumOfP = tableData[index]?.details?.reduce((acc, item) => {
      if (item.text === "P") {
        acc += item.count;
      }
      return acc;
    }, 0);
    return sumOfP
  }
  const getTotalAbsent = (index) => {
    const sumOfA = tableData[index]?.details?.reduce((acc, item) => {
      if (item.text === "A") {
        acc += item.count;
      }
      return acc;
    }, 0);
    return sumOfA
  }

  useEffect(() => {
    if (tableData?.length > 0) {
      console.log("details:", tableData)
    }
  }, [tableData])


  const handleReportDownload = (isPrint) => {
    const doc = new jsPDF({
      orientation: 'landscape', // Set the orientation to landscape
    });
    const contentHeight = 150;
    if (isPrint) {
      setIsPrinting(true)
      setIsDownloading(false)
    }
    else {
      setIsPrinting(false)
      setIsDownloading(true)
    }
    const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image

    doc.setFontSize(12); // Set font size


    // html2canvas(headerImage, {
    //   useCORS: true, // Add this line if the image is from a different domain
    //   allowTaint: true, // Add this line if the image is from a different domain
    // }).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/png");

    // Calculate the width and height of the header image based on the PDF page width
    //   const pdfWidth = doc.internal.pageSize.getWidth() - 50;
    //   const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    //   const headerHeight = pdfHeight;
    //   const tableY = headerHeight + 15;
    // Insert the header image into the PDF
    const topBorderPadding = 20; // Adjust the padding value as needed
    doc.setDrawColor(255, 255, 255); // Black color for border
    doc.setLineWidth(topBorderPadding); // Border width (padding)

    // Draw the top border (padding)
    // doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);

    // Insert the header image into the PDF
    //   doc.addImage(imgData, "PNG", 25, topBorderPadding, pdfWidth, pdfHeight);
    const textX = 15;
    const textY = 20 + 5;
    doc.setFont('helvetica', 'bold'); // Set font to bold
    doc.setFontSize(12); // Set font size

    doc.text("Worker Attendance Report", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("Worker Attendance Report") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), topBorderPadding);

    doc.setFontSize(8); // Set font size
    doc.text("Project Location:", 15, topBorderPadding + 20);
    const firstTextWidth = doc.getStringUnitWidth("Project Location:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${state?.project_location}`, 15 + firstTextWidth + 5, topBorderPadding + 20);
    doc.setFont('helvetica', 'bold');
    doc.text("Project Name:", 15, topBorderPadding + 25);
    const secondTextWidth = doc.getStringUnitWidth("Project Name:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${state?.project_name}`, 15 + secondTextWidth + 5, topBorderPadding + 25);
    doc.setFont('helvetica', 'bold');
    doc.text("Project Id:", 15, topBorderPadding + 30);
    const thirdTextWidth = doc.getStringUnitWidth("Project Id:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${state?.project_id}`, 15 + thirdTextWidth + 5, topBorderPadding + 30);
    doc.setFont('helvetica', 'bold');
    doc.text("Month:", 15, topBorderPadding + 35);
    const fourthTextWidth = doc.getStringUnitWidth("Month:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${months?.filter(elem => elem?.value === state?.month)?.[0]?.month}`, 15 + fourthTextWidth + 5, topBorderPadding + 35);
    doc.setFont('helvetica', 'bold');
    doc.text("Year:", 15, topBorderPadding + 40);
    const fifthText = doc.getStringUnitWidth("Year:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${state?.year}`, 15 + fifthText + 5, topBorderPadding + 40);
    doc.setFont('helvetica', 'bold');
    doc.text("Absent: A", 15, topBorderPadding + 45);
    const sixthTextWidth = doc.getStringUnitWidth("Absent: A") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.text("Present: P", 15 + sixthTextWidth + 20, topBorderPadding + 45);
    //   doc.setFont('helvetica', 'bold');
    //   doc.text("Requisition Month:", 15, topBorderPadding + 45);
    //   const sixthTextWidth = doc.getStringUnitWidth("Requisition Month:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    //   doc.setFont('helvetica', 'normal');
    //   doc.text(`${state?.req_month}`, 15 + sixthTextWidth + 5, topBorderPadding + 45);
    //   doc.setFont('helvetica', 'bold');
    //   doc.text("Requisition Year:", 15, topBorderPadding + 50);
    //   const seventhTextWidth = doc.getStringUnitWidth("Requisition Year:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    //   doc.setFont('helvetica', 'normal');
    //   doc.text(`${state?.req_year}`, 15 + seventhTextWidth + 5, topBorderPadding + 50);
    //   doc.setFont('helvetica', 'bold');
    //   doc.text("Category:", 15, topBorderPadding + 55);
    //   const eighthTextWidth = doc.getStringUnitWidth("Category:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    //   doc.setFont('helvetica', 'normal');
    //   doc.text(`${state?.category}`, 15 + eighthTextWidth + 5, topBorderPadding + 55);

    doc.setFont('helvetica', 'normal');

    const headerSectionHeight = 60; // Adjust the value as needed

    // Calculate the starting Y-coordinate for the table
    const tableStartY = headerSectionHeight;
    doc.setFont('helvetica', 'normal');
    const body = []


    tableData && tableData?.length > 0 && tableData?.forEach((elem, index) => {

      body.push(
        [index + 1,
        elem?.category ?? "",
        elem?.name ?? ""

        ]
      )
      elem?.details?.forEach((item) => { body[index].push(item?.text) })
      body[index].push(getTotalPresent(index))
      body[index].push(getTotalAbsent(index))




    })
    //   body.push(
    //     [{content:"Total Amount (Nu)",colSpan:9,styles:{halign: "right",valign: "middle",fontStyle:"bold"}},
    //     formatNumberWithCommasAndDecimals(getTotalAmount()?getTotalAmount():0.00)])
    //   if (tableData?.length > 0) {
    //     body.push([{ content: "Grand Total", colSpan: 6, styles: { fontStyle: "bold", valign: "middle", halign: "right" } }, { content: formatNumberWithCommasAndDecimals(totalAmount), styles: { halign: "right", fontStyle: "bold" } }])
    //   }
    const headers = [
      { content: "SL No.", colSpan: 1, styles: { valign: "middle", halign: "center", fontSize: 8 } },
      { content: "Category", colSpan: 1, styles: { valign: "middle", halign: "center", fontSize: 8 } },
      { content: "Worker Name", colSpan: 1, styles: { valign: "middle", halign: "center", fontSize: 8 } },
    ]
    {
      Array.from({ length: months?.filter(mn => mn?.value === state?.month)[0]?.max }).forEach((_, index) => (
        headers?.push({ content: index + 1, colSpan: 1, styles: { valign: "middle", halign: "center", fontSize: 8 } })
      ))
    }
    headers?.push({ content: "Total (P)", colSpan: 1, styles: { valign: "middle", halign: "center", fontSize: 8 } })
    headers?.push({ content: "Total (A)", colSpan: 1, styles: { valign: "middle", halign: "center", fontSize: 8 } })
    doc.autoTable({
      startY: topBorderPadding + 55,
      head: [headers],
      body: body, // Use the transformed data here
      theme: "grid",
      styles: {
        fillColor: [255, 255, 255], // Remove background color from the table
        lineColor: [0, 0, 0], // Set border color for main table
        lineWidth: 0.1, // Set border width for main table
        textColor: [0, 0, 0], // Set text color to black
        cellPadding: 2, // Add padding to cells
      },
      columnStyles: {
        1: { cellWidth: 25 },
        2: { cellWidth: 20 }, // "ITEM DESCRIPTION" column width
      },
    });

    // Function to add footer on each page
    //   function addFooter(pageNumber) {
    //     // const footerText = "Hello CMS User";
    //     // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
    //     const footerX = 0; // Starting X position for the footer
    //     const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
    //     const pdfWidth = doc.internal.pageSize.getWidth();
    //     const pdfHeight = 10;
    //    doc.addImage(`${ServiceUrl?.showImageUrl}${state?.footer}`, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
    //   }

    // Get the total number of pages
    //   const totalNumPages = doc.internal.getNumberOfPages();

    //   // Loop through each page to add the footer with page numbers
    //   for (let i = 1; i <= totalNumPages; i++) {
    //     doc.setPage(i);
    //     addFooter(i);
    //   }


    const scaleFactor = 1.0; // Adjust this value as needed
    const pdfBlob = doc.output('blob', { scale: scaleFactor });

    // Create a URL for the Blob
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Open a new window with the PDF URL

    if (isPrint) {
      const newWindow = window.open(pdfUrl, '_blank');

      // After the new window is opened, you can close the URL object
      URL.revokeObjectURL(pdfUrl);
      if (newWindow) {
        newWindow.onload = () => {
          setIsPrinting(false)
          setIsDownloading(false)
          // Wait for the window to load and then trigger the print dialog
          newWindow.print();
        };
      }
    }

    setIsPrinting(false)
    setIsDownloading(false)

    isPrint === false && doc.save('Worker_Attendance_Report.pdf');


  };
  // const checkLeapYear=(year)=> {
  //   // Leap years are divisible by 4
  //   // But if a year is divisible by 100, it must also be divisible by 400 to be a leap year
  //   if((year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0)){
  //     return true
  //   }
  //   // return(year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0)
  //   else{
  //     return false
  //   }

  // }

  return (
    <>
      <Container>
        <div style={{ height: 'auto', width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>

          <Grid container>

            <Grid item xs={12}>
              <Button
                size="small"
                sx={{ width: "100px" }}
                variant="contained"
                color="error"
                onClick={() => { window.history.back() }}
              >
                <Icon color="primary" fontSize="small">
                  skip_previous
                </Icon>
                Back
              </Button>
            </Grid>
          </Grid>
          <SimpleCard>

            <ValidatorForm onSubmit={handleFormSubmit}>
              <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
                <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>Worker Attendance</b></Grid>
              </Grid>
              <Grid container spacing={3}>

                <Grid item sm={4} xs={12}>
                  <DecoratedTextField
                    select
                    size="small"
                    fullWidth
                    sx={{ mb: 3 }}
                    label="Project ID"
                    id="project_location_id"
                    variant="outlined"
                    name="project_location_id"
                    value={state?.project_location_id}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["Project ID is required"]}
                  >
                    {projectLocationListForAgency?.map((item) => (
                      <MenuItem value={item?.project_location_id} key={item?.project_id}>
                        {item?.project_id}
                      </MenuItem>
                    ))}
                  </DecoratedTextField>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <DecoratedTextField
                    select
                    size="small"
                    fullWidth
                    sx={{ mb: 3 }}
                    label="Requisition No."
                    id="requisition_id"
                    variant="outlined"
                    name="requisition_id"
                    value={state?.requisition_id}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["Requisition Id is required"]}
                  >
                    {requisitionList?.map((item) => (
                      <MenuItem value={item?.resourcerequisition_id} key={item?.resourcerequisition_id}>
                        {item?.requisition_no}
                      </MenuItem>
                    ))}
                  </DecoratedTextField>

                </Grid>
              </Grid>

              <Grid container spacing={3}>
                {/* <Grid item lg={4} md={6} sm={6} xs={12}>
                  <DecoratedTextField
                    select
                    size="small"
                    fullWidth
                    sx={{ mb: 3 }}
                    label="BML Item"
                    id="resourcerequisition_details_id"
                    variant="outlined"
                    name="resourcerequisition_details_id"
                    value={state?.resourcerequisition_details_id}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["BML Item is required"]}
                  >
                    {bmlItemsList?.map((item) => (
                      <MenuItem value={item?.resourcerequisition_details_id} key={item?.resourcerequisition_id}>
                        {item?.bml_item}
                      </MenuItem>
                    ))}
                  </DecoratedTextField>

                </Grid> */}
                <Grid item sm={4} xs={12}>
                  <DecoratedTextField
                    select
                    size="small"
                    fullWidth
                    sx={{ mb: 3 }}
                    label="Month"
                    id="month"
                    variant="outlined"
                    name="month"
                    value={state?.month}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["Month is required"]}
                  >
                    {months?.map((item) => (
                      <MenuItem value={item?.value} key={item?.value}>
                        {item?.month}
                      </MenuItem>
                    ))}
                  </DecoratedTextField>

                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    type="number"
                    size="small"
                    fullWidth
                    name="year"
                    id="year"
                    label="Year"
                    value={state?.year || ""}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["Year is required"]}
                    onKeyDown={(event) => {
                      if (event?.code === "ArrowDown" || event?.code === "ArrowUp" ||
                        event?.key === "-" || event?.key === "+" || event?.key === "." || event?.key?.toLowerCase() === "e") {
                        event.preventDefault();
                      }

                    }}
                  />

                </Grid>

                <Grid item sm={4} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>

                  <Button size="medium" color="success" variant="contained" type="submit" style={{ marginTop: -20 }} >View</Button>

                </Grid>

              </Grid>

            </ValidatorForm>
          </SimpleCard>
          {
            tableData && tableData?.length > 0 &&
            <Grid container>
              <Grid item xs={12}>
                <Box component="span" display="flex" justifyContent="flex-end">
                  <Button
                    size="small"
                    sx={{ width: "130px", marginLeft: 4 }}
                    variant="contained"
                    color="warning"
                    startIcon={<CloudDownload />}
                    onClick={() => { handleReportDownload(false) }}
                  >
                    Download
                  </Button>
                  <Button
                    size="small"
                    sx={{ width: "100px", marginLeft: 2 }}
                    variant="contained"
                    color="primary"
                    startIcon={<Print />}
                    onClick={() => { handleReportDownload(true) }}
                  >
                    Print
                  </Button>
                </Box>
              </Grid>

            </Grid>}
          {/* table */}
          {
            tableData && tableData?.length > 0 &&
            <Card sx={{ padding: 3 }}>

              <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
                <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
                  {/* <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
          <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${state?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />

        </div> */}

                  <Grid item xs={12} align='center' >
                    <FormLabel sx={{ fontSize: 22 }} ><b>Worker Attendance Report</b></FormLabel>
                  </Grid>


                  <Grid container >
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Project Location: </b>{state?.project_location}</FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{state?.project_name}</FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Project ID: </b>{state?.project_id}</FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Requisition No.: </b></FormLabel>&nbsp;&nbsp;&nbsp;
                      <FormLabel sx={{ fontSize: 18 }}><b>Requisition Date: </b></FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Month: </b>{months?.filter(elem => elem?.value === state?.month)?.[0]?.month}</FormLabel>
                      &nbsp;&nbsp;&nbsp;
                      <FormLabel sx={{ fontSize: 18 }}><b>Year: </b>{state?.year}</FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Absent:  A </b></FormLabel>&nbsp;&nbsp;&nbsp;
                      <FormLabel sx={{ fontSize: 18 }}><b>Present:  P </b></FormLabel>
                    </Grid>

                    {/* <Grid item xs={12} sx={{ marginTop: 2 }}>
            <FormLabel sx={{ fontSize: 18 }}><b>Requisition Month: </b>{state?.req_month}</FormLabel>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <FormLabel sx={{ fontSize: 18 }}><b>Requisition Year: </b>{state?.req_year}</FormLabel>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <FormLabel sx={{ fontSize: 18 }}><b>Category: </b>{state?.category}</FormLabel>
          </Grid> */}


                  </Grid>
                </div>

                <div id="tableData" style={{ overflowX: 'auto', minWidth: '750px' }}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead style={{ backgroundColor: '#87CEEB' }}>
                        <TableRow style={{ border: '1px solid #000' }}>
                          <TableCell align="center" style={{ border: '1px solid #000' }}>Sl No.</TableCell>
                          <TableCell align="left" width={150} style={{ border: '1px solid #000' }}>Category</TableCell>
                          <TableCell align="left" width={150} style={{ border: '1px solid #000' }}>Worker Name</TableCell>
                          {/* {
                    for(let i=1;i<=checkLeapYear(state?.year)&&state?.month===2?checkedMonth?.max+1:checkedMonth?.max;i++){
                        <TableCell align="center">Rate</TableCell>
                    }
                } */}
                          {Array.from({ length: months?.filter(mn => mn?.value === state?.month)[0]?.max }).map((_, index) => (
                            <TableCell align="center" style={{ border: '1px solid #000' }}>{index + 1}</TableCell>
                          ))}
                          <TableCell align="center" style={{ border: '1px solid #000' }}>Total(P)</TableCell>
                          <TableCell align="center" style={{ border: '1px solid #000' }}>Total(A)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          tableData?.map((elem, index) => {
                            return (
                              <TableRow style={{ borderRight: '1px solid #000' }}>
                                <TableCell align="center" style={{ border: '1px solid #000' }}>{index + 1}</TableCell>
                                <TableCell align="left" style={{ borderRight: '1px solid #000' }}>{elem?.category ?? ""}</TableCell>
                                <TableCell align="left" style={{ borderRight: '1px solid #000' }}>{elem?.name ?? ""}</TableCell>
                                {
                                  elem?.details?.map(item => {
                                    return (
                                      <TableCell align="center" style={{ borderRight: '1px solid #000' }}>{item?.text}</TableCell>
                                    )
                                  })
                                }
                                <TableCell align="right" style={{ borderRight: '1px solid #000' }}>{getTotalPresent(index)}</TableCell>
                                <TableCell align="right" style={{ borderRight: '1px solid #000' }}>{getTotalAbsent(index)}</TableCell>
                              </TableRow>
                            )
                          })


                        }
                        {/* <TableRow>
                      <TableCell colSpan={9} align="center"><b>Total Amount</b></TableCell>
                      <TableCell align="right">{formatNumberWithCommasAndDecimals(getTotalAmount() ? getTotalAmount() : 0)}</TableCell>
                    </TableRow> */}
                        {/* {tableData?.length > 0 &&
                <TableRow>
                  <TableCell colSpan={6} align="right"><b>Grand Total</b></TableCell>
                  <TableCell align="right"><b>{formatNumberWithCommasAndDecimals(totalAmount)}</b></TableCell>
                </TableRow>
              } */}

                      </TableBody>
                    </Table>
                  </TableContainer>

                </div>
              </div>
            </Card>}

        </div>
        {loading && (
          <Loader
            show={loading}
          />
        )}
        {(isPrinting || isDownloading) && (
          <Loader
            show={isPrinting ? isPrinting : isDownloading}
            text="Please Wait While Generating PDF..."
          />
        )}
      </Container>
    </>
  )
}