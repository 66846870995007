


export const AppConstants = {
    newParentAgency: "New Agency",
    editParentAgency: "Edit Agency",
    newDepartment: "New Department",
    editDepartment: "Edit Department",
    publishedSuccessfully: 'Published Successfully',
    newDivision: "New Division",
    editDivision: "Edit Division",
    somethingWentWrong: "Something went wrong",
    editHeaderFooter: "Edit Header/Footer",
    editLogo: "Edit Logo",
    newHeaderFooter: "New Header/Footer",
    newLogo: "New Logo",
    errorLeftLogo: "Please select left logo",
    errorLogo: "Please select logo",
    errorRightLogo: "Please select right logo",
    errorHeader: "Please select header",
    errorFooter: "Please select footer",
    updateWorkType: 'Update Work Type',
    addWorkType: 'Add Work Type',
    editRecoveryDetails: 'Edit Recovery Details',
    newRecoveryDetails: 'Advance Recovery',
    loadingMessage: 'Processing...Please Wait',
    editHiringAgency: 'Edit Hiring Agency',
    newHiringAgency: 'New Hiring Agency',
    editMachineryRegistry: 'Edit Machinery',
    newMachineryRegistry: 'New Machinery',
    noDataFoundEgp: 'No data found for this tender id',
    previousBillwarning: "There is pending RA Bill for approval. You can't submit",
    coefficientDecimalLimit: 5,
    noDataFound: "No Data found",
    completedMessage: "RA Bill has been completed successfully",

}
