import { Button, Card, Dialog, FormLabel, Grid, Icon, MenuItem, TextField, ThemeProvider, createTheme, styled } from "@mui/material";
import { Box } from "@mui/system";
import { Breadcrumb, Loader, SimpleCard } from "app/components";
import { H4 } from "app/components/Typography";
import useAuth from "app/hooks/useAuth";
import { getBMLItemsForReport } from "app/redux/actions/ReportsAction";
import { getAllProjectLocationByAgency, getAllResourceRequisitionByProjectLocation, getMusterRollBillByProjectLocation, getWorkerAttendanceForMusterRollBilling, saveOrUpdateMusterRollBill } from "app/redux/actions/ResourceForeCastActions";
import { AppConstants } from "app/utils/AppConstants";
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation, currencyFormat, DecimalInputField, getTwoDigitDecimal } from "app/utils/utils";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { cmsDateFormat } from "app/utils/constant";
import { numToWords } from "app/utils/numToWords";
import { ProjectStatus } from "app/utils/ProjectStatus";

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const MyCard = styled(Card)(() => ({
  "& thead": { "& th": { padding: 10 } },
  "& td": { borderBottom: "none" },
  "& td": { padding: "5px !important", paddingLeft: "10px !important" },
}));


const theme = createTheme({

  components: {
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#87CEEB",
        }
      }
    }
  }
});

const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

export const MusterRollBillSubmission = () => {

  const [isLoad, setIsLoad] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [requisitionList, setRequisitionList] = useState()
  const [shouldOpenMusterRollBillList, setShouldOpenMusterRollBillList] = useState(false)
  const [viewState, setViewState] = useState({
    project_name: "",
    project_location_id: "",
  })
  const auth = useAuth()
  const [billList, setBillList] = useState()
  const [bmlItemsList, setBmlItemsList] = useState()
  const [workerList, setWorkerList] = useState()
  const [newModalOpen, setNewModalOpen] = useState(false)
  const { projectLocationListForAgency } = useSelector((state) => state?.resourceForecast)
  const [state, setState] = useState({
    project_name: "",
    project_location_id: "",
  })
  const months =
    [
      {
        value: 1,
        month: "January",
        max: 31
      },
      {
        value: 2,
        month: "February",
        max: 30
      },
      {
        value: 3,
        month: "March",
        max: 31
      },
      {
        value: 4,
        month: "April",
        max: 30
      },
      {
        value: 5,
        month: "May",
        max: 31
      },
      {
        value: 6,
        month: "June",
        max: 30
      },
      {
        value: 7,
        month: "July",
        max: 31
      },
      {
        value: 8,
        month: "August",
        max: 31
      },
      {
        value: 9,
        month: "September",
        max: 30
      },
      {
        value: 10,
        month: "October",
        max: 31
      },
      {
        value: 11,
        month: "November",
        max: 30
      },
      {
        value: 12,
        month: "December",
        max: 31
      }]

  const columns = [
    {
      name: "index",
      label: "Sl No.",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (rowIndex, dataIndex) => rowIndex + 1
      },
    },
    {
      name: "category", // field name in the row object
      label: "Category", // column title that will be shown in table
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "worker_name", // field name in the row object
      label: "Worker Name", // column title that will be shown in table
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "unit_name", // field name in the row object
      label: "Unit", // column title that will be shown in table
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "noofdays", // field name in the row object
      label: "No. of Days", // column title that will be shown in table
      options: {
        filter: true,
        sort: false
      }
    }, {
      name: "rate",
      label: "Rate (Nu)",
      options: { filter: true,
        customBodyRender: (value) => {
          return currencyFormat(value);
        },
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
        
       },
    }
, {
      name: "amount",
      label: "Amount (Nu)",
      options: { filter: true,
        customBodyRender: (value) => {
          return currencyFormat(value);
        },
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
        
       },
    }
  ];
  const billColumns = [
    {
      name: "index",
      label: "Sl No.",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (rowIndex, dataIndex) => rowIndex + 1
      },
    },
    {
      name: "project_id", // field name in the row object
      label: "Project ID", // column title that will be shown in table
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (rowIndex, dataIndex) => viewState?.project_id
      }
    },
    {
      name: "project_name", // field name in the row object
      label: "Project Name", // column title that will be shown in table
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (rowIndex, dataIndex) => viewState?.project_name
      }
    }, {
      name: "musterroll_no", // field name in the row object
      label: "Muster Roll No",
      options: {
        filter: true,
        sort: false
      }
    }, {
      name: "amount", // field name in the row object
      label: "Muster Roll Date", // column title that will be shown in table
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (rowIndex, dataIndex) => moment(billList[rowIndex]?.musterroll_date).format("DD-MM-YYYY")
      }
    },
    {
      name: "status_id", // field name in the row object
      label: "Status", // column title that will be shown in table
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (rowIndex, dataIndex) => billList[rowIndex]?.status_id === 4 ? "SUBMITTED" : billList[rowIndex]?.status_id === 6 ? "Verified" :
          billList[rowIndex]?.status_id === ProjectStatus.RECOMMENDED ? "Recommended" : "Approved"

      }
    }
  ];


  const showLoader = () => {
    setIsLoad(true)
  }

  const hideLoader = () => {
    setIsLoad(false)
  }
  //   getMusterRollBillByProjectLocation
  const showToast = (msg) => {
    const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  }

  useEffect(() => {
    dispatch(getAllProjectLocationByAgency(showLoader, hideLoader, showToast))
  }, [])

  const handleOpenNewModal = () => {

    setNewModalOpen(true)
  }
  const handleCloseNewModal = () => {
    setState({
      project_name: "",
      project_location_id: "",
    })
    setWorkerList()
    setBmlItemsList()
    setRequisitionList()
    setNewModalOpen(false)
  }

  const handleAccountsItemChange = (event, source) => {
    
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const handleChange = (event, source) => {
    setWorkerList()
    if (event.target.name === "project_location_id") {
      setRequisitionList()
      setBmlItemsList()
      const filteredArray = projectLocationListForAgency.filter(project => {
        return project.project_location_id === event.target.value
      });

      setState({
        [event.target.name]: event.target.value,
        project_name: filteredArray[0].project_name,
        project_location_id: filteredArray[0].project_location_id,
        project_id: filteredArray[0].project_id,
        project_location: filteredArray[0].project_location,
        muster_roll_no: "",
        muster_roll_date: "",
        month: null,
        accounts_name_designation: "",
        email_id: "",
        recipient_name_designation: "",
        recipient_email: "",
        recipient_account_no: "",
        other1_email: "",
        other1_name_designation: "",
        other2_email: "",
        other2_name_designation: "",
        remarks: ""
      });
      setIsLoad(true)
      getAllResourceRequisitionByProjectLocation({ project_location_id: event.target.value }).then(res => {
        setIsLoad(false)

        if (res?.status < 300) {
          if (res?.data?.obj?.length > 0) {
            //setRequisitionList(res?.data?.obj)
            // Filter the data based on resourceforecast_category_id equal to 3
            const filteredData = res.data.obj.filter(item => item.resourceforecast_category_id === 1);

            // Set the filtered data to the requisitionList state
            setRequisitionList(filteredData);
          }
          else {
            const key = enqueueSnackbar("No requisition found", { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
          }


        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(error => {
        setIsLoad(false)
        const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })


    }
    else if (event.target.name === "requisition_id") {
      setBmlItemsList()
      const filteredArray = requisitionList.filter(requisition => {
        return requisition.resourcerequisition_id === event.target.value
      });
      const newState = { ...state }
      setState({
        [event.target.name]: event.target.value,
        project_name: newState.project_name,
        project_location_id: newState.project_location_id,
        project_id: newState.project_id,
        project_location: newState.project_location,
        req_no: filteredArray[0].requisition_no,
        req_date: moment(filteredArray[0].requisition_date).format("DD-MM-YYYY"),
        req_year: filteredArray[0].req_year,
        muster_roll_no: "",
        muster_roll_date: "",
        year: filteredArray[0].req_year,
        month: filteredArray[0].req_month,
        month_name: months[filteredArray[0].req_month - 1].month,
        remarks: "",
        email_id: "",
        accounts_name_designation: "",
        recipient_name_designation: "",
        recipient_email: "",
        recipient_account_no: "",
        other1_email: "",
        other1_name_designation: "",
        other2_email: "",
        other2_name_designation: "",
        //   category: filteredArray[0].resourceforecast_category_name
      });
      setIsLoad(true)
      getBMLItemsForReport(event.target.value).then(res => {
        setIsLoad(false)
        if (res?.status < 300) {
          if (res?.data?.success) {
            if (res?.data?.obj?.item?.length > 0) {
              setBmlItemsList(res?.data?.obj?.item)
            }
            else {
              const key = enqueueSnackbar("No BML items found", { variant: 'info' });
              SnackBarProperty.info(key, closeSnackbar);
            }
          }
          else {
            const key = enqueueSnackbar(res?.data?.message, { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
          }
        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(err => {
        setIsLoad(false)
        const key = enqueueSnackbar(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })
    }
    else if (event.target.value === "month") {
      const newState = { ...state }
      setState({
        [event.target.name]: event.target.value,
        requisition_id: newState?.requisition_id,
        project_name: newState.project_name,
        project_location_id: newState.project_location_id,
        project_id: newState.project_id,
        project_location: newState.project_location,
        req_no: newState.requisition_no,
        req_date: moment(newState?.requisition_date).format("DD-MM-YYYY"),
        req_year: newState.req_year
      })
    }
    else if (event.target.value === "year") {
      setState({ ...state, [event.target.name]: parseInt(event.target.value) })
    }
    else if (event.target.name === "resourcerequisition_details_id") {
      const filteredArray = bmlItemsList.filter(item => {
        return item.resourcerequisition_details_id === event.target.value
      });
      setState({
        ...state,
        [event.target.name]: event.target.value,
        unit: filteredArray[0]?.unit_name
      })
    }
    else {
      setState({ ...state, [event.target.name]: event.target.value })
    }
  }

  const handleViewState = (event) => {
    if (event.target.name === "project_location_id") {
      const filteredArray = projectLocationListForAgency.filter(project => {
        return project.project_location_id === event.target.value
      });


      setViewState({
        [event.target.name]: event.target.value,
        project_name: filteredArray[0].project_name,
        project_location_id: filteredArray[0].project_location_id,
        project_id: filteredArray[0].project_id,
        project_location: filteredArray[0].project_location
      });
    }
  }

  const handleGetWorkerAttendance = () => {
    // console.log('noce', state)
    // if (checkValidation(state?.muster_roll_date) === false) {
    //   const key = enqueueSnackbar("Please select muster roll date", { variant: 'error' });
    //   SnackBarProperty.error(key, closeSnackbar);
    // }
    // else if (state?.year?.length !== 4) {
    //   const key = enqueueSnackbar("Please enter valid year", { variant: 'error' });
    //   SnackBarProperty.error(key, closeSnackbar);
    // }
    //  else {
    setIsLoad(true)
    setWorkerList()
    getWorkerAttendanceForMusterRollBilling(state?.requisition_id, state?.month, state?.year).then(res => {
      setIsLoad(false)
      if (res?.status < 300) {
        if (res?.data?.length > 0) {
          setWorkerList(res?.data)
        }
        else {
          const key = enqueueSnackbar("No BML items found", { variant: 'info' });
          SnackBarProperty.info(key, closeSnackbar);
        }

      }
      else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(err => {
      setIsLoad(false)
      const key = enqueueSnackbar(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    })
    //  }
  }

  const handleSubmit = () => {
    if (checkValidation(state?.muster_roll_date) === false) {
      const key = enqueueSnackbar("Please enter muster roll date", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    }
    else if (checkValidation(state?.email_id) === false) {
      const key = enqueueSnackbar("Please enter accounts email id", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);

    } else if (checkValidation(state?.remarks) === false) {
      const key = enqueueSnackbar("Please enter remarks", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    }
    else {
      setIsLoad(true)
      const params = {
        project_location_id: state?.project_location_id,
        resourcerequisition_id: state?.requisition_id,
        resourcerequisition_details_id: state?.resourcerequisition_details_id,
        bill_month: state?.month,
        bill_year: state?.year,
        musterroll_no: state?.muster_roll_no,
        musterroll_date: new Date(state?.muster_roll_date),
        remarks: state?.remarks,
        email_id: state?.email_id,
        total_amount: getTotalAmount(),
        status_id: 4,
        musterRollBillDetailsList: workerList,
        accounts_name_designation: state?.accounts_name_designation,
        recipient_name_designation: state?.recipient_name_designation,
        recipient_email: state?.recipient_email,
        recipient_account_no: state?.recipient_account_no,
        other1_email: state?.other1_email,
        other1_name_designation: state?.other1_name_designation,
        other2_email: state?.other2_email,
        other2_name_designation: state?.other2_name_designation,
      }
      saveOrUpdateMusterRollBill(params).then(res => {
        setIsLoad(false)
        if (res?.status < 300 && res?.data?.success) {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'success' });
          SnackBarProperty.success(key, closeSnackbar);

          handleCloseNewModal()
        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(err => {
        setIsLoad(false)
        const key = enqueueSnackbar(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);
      })
    }
  }
  const handleGetBillList = () => {
    setIsLoad(true)
    setBillList()

    getMusterRollBillByProjectLocation(viewState?.project_location_id).then(res => {
      setIsLoad(false)
      if (res?.status < 300) {
        // if (res?.data?.obj?.length > 0) {

          const filteredBills = res?.data?.obj?.filter((elem) => elem?.created_by === auth?.user?.id)
          if (filteredBills?.length > 0) {
            setBillList(filteredBills)
          }
          setShouldOpenMusterRollBillList(true)

        //   else {
        //     const key = enqueueSnackbar("No Muster Roll Bill found", { variant: 'info' });
        //     SnackBarProperty.info(key, closeSnackbar);
        //   }


        // }
        // else {
        //   const key = enqueueSnackbar("No Muster Roll Bill Found", { variant: 'info' });
        //   SnackBarProperty.info(key, closeSnackbar);
        // }
      }
      else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);
      }
    }).catch(error => {
      setIsLoad(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    })
  }
  const getTotalAmount = () => {
    const totalAmount = workerList.reduce((sum, worker) => sum + getTwoDigitDecimal(worker.amount), 0);
    return totalAmount
  }

  return (
    <Container>
      <div className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Departmental Works", path: "#" }, { name: "Muster Roll Submission" }]} />
      </div>
      <Button
        sx={{ mb: 2 }}
        color="secondary"
        variant="contained"
        title="Add New Muster Roll Bill"
        onClick={() => { handleOpenNewModal() }}
      >
        <Icon>control_point</Icon>&nbsp; New
      </Button>

      <SimpleCard>
        {/* <Button size="medium" color="success" variant="contained"  onClick={() => { handleOpenNewModal() }}  >
          <Icon color="secondary" fontSize="small">
            add
          </Icon>
          ADD NEW
        </Button> */}

        <ValidatorForm onSubmit={handleGetBillList}>
          <Grid container spacing={3} sx={{ marginLeft: 0.5 }}>
            <Grid item sm={4} xs={12}>
              <DecoratedTextField
                select
                size="small"
                fullWidth
                label="Project ID"
                id="project_location_id"
                variant="outlined"
                name="project_location_id"
                value={viewState?.project_location_id}
                onChange={handleViewState}
                validators={["required"]}
                errorMessages={["Project ID is required"]}
              >
                {projectLocationListForAgency?.map((item) => (
                  <MenuItem value={item?.project_location_id} key={item?.project_id}>
                    {item?.project_id}
                  </MenuItem>
                ))}
              </DecoratedTextField>
            </Grid>

            <Grid item sm={4} md={3} xs={12} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>

              <Button size="medium" color="primary" variant="contained" type="submit" style={{ marginTop: -20, gap: 2 }} >
                <Icon color="secondary" fontSize="small">
                  visibility
                </Icon>
                View
              </Button>

            </Grid>
          </Grid>

        </ValidatorForm>
        {shouldOpenMusterRollBillList && 
        <ThemeProvider theme={theme}>
          <MUIDataTable
            data={billList}
            columns={billColumns}
            title={"List of Muster Roll Bill"}
            options={{
              fixedHeader: true,
              sort: false,
              responsive: 'scroll',
              selectableRows: "none",
              filterType: "textField",
              elevation: 0,
              rowsPerPageOptions: [10, 20, 40, 80, 100],
            }}
          />
        </ThemeProvider>
      }
      </SimpleCard>

      {newModalOpen &&
        <Dialog open={newModalOpen} maxWidth="lg" fullWidth={true}>
          <Box>
            <Card sx={{ padding: 4, overflow: "auto" }} elevation={6}>
              <H4 sx={{ mb: "20px" }}><b>New Muster Roll Bill</b></H4>
              <ValidatorForm onSubmit={handleGetWorkerAttendance}>
                <Grid container spacing={3}>
                  <Grid item sm={4} md={3} xs={12}>
                    <DecoratedTextField
                      select
                      size="small"
                      fullWidth
                      label="Project ID"
                      id="project_location_id"
                      variant="outlined"
                      name="project_location_id"
                      value={state?.project_location_id}
                      onChange={handleChange}
                      validators={["required"]}
                      errorMessages={["Project ID is required"]}
                    >
                      {projectLocationListForAgency?.map((item) => (
                        <MenuItem value={item?.project_location_id} key={item?.project_id}>
                          {item?.project_id}
                        </MenuItem>
                      ))}
                    </DecoratedTextField>
                  </Grid>
                  <Grid item sm={4} md={4} xs={12}>
                    <DecoratedTextField
                      size="small"
                      fullWidth
                      disabled
                      label="Project Name"
                      id="project_name"
                      variant="outlined"
                      name="project_name"
                      value={state?.project_name ? state?.project_name : ""}
                      validators={["required"]}
                      errorMessages={["Project Name is required"]}
                    >
                    </DecoratedTextField>
                  </Grid>
                  <Grid item sm={4} md={3} xs={12}>
                    <DecoratedTextField
                      select
                      size="small"
                      fullWidth
                      label="Requisition No."
                      id="requisition_id"
                      variant="outlined"
                      name="requisition_id"
                      value={state?.requisition_id}
                      onChange={handleChange}
                      validators={["required"]}
                      errorMessages={["Requisition Id is required"]}
                    >
                      {requisitionList?.map((item) => (
                        <MenuItem value={item?.resourcerequisition_id} key={item?.resourcerequisition_id}>
                          {item?.requisition_no}
                        </MenuItem>
                      ))}
                    </DecoratedTextField>

                  </Grid>
                  <Grid item sm={4} md={2} xs={12}>
                    <DecoratedTextField

                      size="small"
                      fullWidth
                      disabled
                      label="Requisition Date"
                      id="req_date"
                      variant="outlined"
                      name="req_date"
                      value={state?.req_date ? state?.req_date : ""}
                      validators={["required"]}
                      errorMessages={["Requisition date is required"]}
                    >
                    </DecoratedTextField>
                  </Grid>

                  <Grid item sm={4} md={2} xs={12}>
                    <DecoratedTextField
                      // select
                      disabled
                      size="small"
                      fullWidth
                      label="Month"
                      id="month"
                      variant="outlined"
                      name="month"
                      value={state?.month_name || ""}
                      onChange={handleChange}
                      validators={["required"]}
                      errorMessages={["Month is required"]}
                    />
                    {/* {months?.map((item) => (
                        <MenuItem value={item?.value} key={item?.value}>
                          {item?.month}
                        </MenuItem>
                      ))} */}
                    {/* </DecoratedTextField> */}

                  </Grid>
                  <Grid item sm={4} md={2} xs={12}>
                    <DecimalInputField
                      type="number"
                      size="small"
                      fullWidth
                      disabled
                      label="Year"
                      id="year"
                      variant="outlined"
                      name="year"
                      value={state?.year || ""}
                      onChange={handleChange}
                      validators={["required"]}
                      errorMessages={["Year is required"]}
                      onKeyDown={(event) => {
                        if (event?.code === "ArrowDown" || event?.code === "ArrowUp" ||
                          event?.key === "-" || event?.key === "+" || event?.key === "." || event?.key?.toLowerCase() === "e") {
                          event.preventDefault();
                        }

                      }}
                    />

                  </Grid>

                  <Grid item sm={4} md={3} xs={12}>
                    <DecoratedTextField
                      size="small"
                      fullWidth
                      label="Muster Roll No"
                      id="muster_roll_no"
                      variant="outlined"
                      name="muster_roll_no"
                      value={state?.muster_roll_no}
                      onChange={handleChange}
                      validators={["required"]}
                      errorMessages={["Muster Roll No is required"]}

                    />

                  </Grid>
                  <Grid item sm={4} md={2} xs={12}>
                    <DatePicker
                      label="Muster Roll Date"
                      value={dayjs(state?.muster_roll_date)}
                      format={cmsDateFormat}
                      slotProps={{
                        textField: {
                          size: 'small',
                          required: true,
                          fullWidth: true,
                          variant: 'outlined',
                          error: false,
                        },
                      }}
                      onChange={(newValue) =>
                        setState({
                          ...state, ['muster_roll_date']: dayjs(newValue).format("YYYY-MM-DDTHH:mm:ss")
                        })
                      }
                    />

                  </Grid>
                  <Grid item sm={4} md={3} xs={12} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>

                    <Button size="medium" color="success" variant="contained" type="submit" style={{ marginTop: -20, gap: 2 }} >
                      <Icon color="secondary" fontSize="small">
                        visibility
                      </Icon>
                      View
                    </Button>

                  </Grid>
                </Grid>

              </ValidatorForm>

              <ValidatorForm onSubmit={handleSubmit}>
                {workerList && workerList?.length > 0 && <>
                  <MyCard sx={{ width: "100%", overflow: "auto" }} elevation={6}>
                    <ThemeProvider theme={theme}>
                      <MUIDataTable
                        data={workerList}
                        columns={columns}
                        title={"List of Workers"}
                        options={{
                          fixedHeader: true,
                          sort: false,
                          responsive: 'scroll',
                          selectableRows: "none",
                          filterType: "textField",
                          elevation: 0,
                          rowsPerPageOptions: [10, 20, 40, 80, 100],
                          rowsPerPage: 20,
                          search: false,
                          download: false,
                          filter: false,
                          print: false,
                          viewColumns: false,
                        }}
                      />
                    </ThemeProvider>
                  </MyCard>
                  <FormLabel style={{ textAlign: 'right', display: 'block', marginTop: 10, marginBottom: 10 }}><b>Total Amount (Nu): </b>{currencyFormat(getTotalAmount())} ({numToWords(parseFloat(getTotalAmount()).toFixed(2))})</FormLabel>
                  <Grid container spacing={3} >
                    <Grid item sm={4} md={4} xs={12}>
                      <DecoratedTextField
                        type="text"
                        size="small"
                        fullWidth
                        sx={{ mb: 2 }}
                        name="accounts_name_designation"
                        label="Accounts Name/Designation"
                        value={state?.accounts_name_designation}
                        onChange={handleAccountsItemChange}
                        validators={["required"]}
                        errorMessages={["Name/Designation is required"]}
                      />
                    </Grid>
                    <Grid item sm={4} md={3} xs={12}>
                      <DecoratedTextField
                        type="text"
                        size="small"
                        fullWidth
                        sx={{ mb: 2 }}
                        name="email_id"
                        label="Accounts Email ID"
                        value={state?.email_id}
                        onChange={handleAccountsItemChange}
                        validators={["required"]}
                        errorMessages={["Email ID is required"]}
                      />
                    </Grid>

                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item sm={4} md={4} xs={12}>
                      <DecoratedTextField
                        type="text"
                        size="small"
                        fullWidth
                        sx={{ mb: 2 }}
                        name="recipient_name_designation"
                        label="Recipient Name/Designation"
                        value={state?.recipient_name_designation}
                        onChange={handleAccountsItemChange}
                        validators={["required"]}
                        errorMessages={["Name/Designation is required"]}
                      />
                    </Grid>
                    <Grid item sm={4} md={3} xs={12}>
                      <DecoratedTextField
                        type="text"
                        size="small"
                        fullWidth
                        sx={{ mb: 2 }}
                        name="recipient_email"
                        label="Recipient Email ID"
                        value={state?.recipient_email}
                        onChange={handleAccountsItemChange}
                        validators={["required"]}
                        errorMessages={["Email ID is required"]}
                      />
                    </Grid>

                    <Grid item sm={4} md={3} xs={12}>
                      <DecoratedTextField
                        type="text"
                        size="small"
                        fullWidth
                        multiline={true}
                        name="recipient_account_no"
                        label="Recipient Account No."
                        value={state?.recipient_account_no}
                        onChange={handleAccountsItemChange}
                        validators={["required"]}
                        errorMessages={["Account No. is required"]}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} >
                    <Grid item sm={4} md={4} xs={12}>
                      <DecoratedTextField
                        type="text"
                        size="small"
                        fullWidth
                        sx={{ mb: 2 }}
                        name="other1_name_designation"
                        label="Others Name/Designation"
                        value={state?.other1_name_designation}
                        onChange={handleAccountsItemChange}

                      />
                    </Grid>
                    <Grid item sm={4} md={3} xs={12}>
                      <DecoratedTextField
                        type="text"
                        size="small"
                        fullWidth
                        sx={{ mb: 2 }}
                        name="other1_email"
                        label="Others Email ID"
                        value={state?.other1_email}
                        onChange={handleAccountsItemChange}

                      />
                    </Grid>

                  </Grid>
                  <Grid container spacing={3} >
                    <Grid item sm={4} md={4} xs={12}>
                      <DecoratedTextField
                        type="text"
                        size="small"
                        fullWidth
                        sx={{ mb: 2 }}
                        name="other2_name_designation"
                        label="Others Name/Designation"
                        value={state?.other2_name_designation}
                        onChange={handleAccountsItemChange}

                      />
                    </Grid>
                    <Grid item sm={4} md={3} xs={12}>
                      <DecoratedTextField
                        type="text"
                        size="small"
                        fullWidth
                        sx={{ mb: 2 }}
                        name="other2_email"
                        label="Others Email ID"
                        value={state?.other2_email}
                        onChange={handleAccountsItemChange}

                      />
                    </Grid>

                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item sm={4} md={4} xs={12}>
                      <DecoratedTextField
                        type="text"
                        rows={3}
                        size="small"
                        fullWidth
                        multiline={true}
                        name="remarks"
                        label="Remarks"
                        value={state?.remarks}
                        onChange={handleAccountsItemChange}
                        // validators={["required"]}
                        // errorMessages={["Remarks is required"]}
                      />
                    </Grid>

                  </Grid>

                </>
                }
                <Grid container>
                  <Grid items sm={12} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: "100%", gap: 4 }}>

                    <Button size="medium" color="success" disabled={workerList?.length > 0 ? false : true} variant="contained" type="submit" >
                      Submit
                    </Button>

                    <Button size="medium" color="warning" variant="contained" onClick={() => { handleCloseNewModal() }}  >
                      Close
                    </Button>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </Card>
          </Box>
        </Dialog>
      }
      {isLoad && (
        <Loader
          show={isLoad}
        />
      )}

    </Container>
  );
};
